import TextField from "./TextField";

export default class PasswordField extends TextField {

  constructor() {
    super();

    this.setType('password');
  }

}