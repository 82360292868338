const $ = window.jQuery;
require('jquery-confirm')(window, $);

import Component from "../../../../js/components/Component";

export default class ScaryJqueryConfirm extends Component {

  constructor() {
    super();

    this.name = 'jqueryconfirm';
    this.name = this.getUniqId();
    this.title = '';
    this.content = '';
    this.column_class = '';
    this.on_content_cb = () => {};
    this.buttons = {};
    this.type = '';
    this.type_animation = true;
    this.draggable = true;
    this.animation = 'zoom';
    this.close_animation = 'top';
    this.animation_bounce = 1.3;
    this.animation_speed = 500;
    this.background_dismiss = false;
    this.data = {};

    this.alert_type = 'red';
    this.confirm_type = 'orange';
  }

  modalAlert(ok_cb) {
    return this
      .setType(this.alert_type)
      .setAnimationBounce(2)
      .setAnimation('RotateXR')
      .setCloseAnimation('right')
      .setBackgroundDismiss(true)
      .setButton('confirm', {
        text: 'Ok',
        action: typeof ok_cb !== 'function' ? () => {} : () => { ok_cb.call(this, this.data); },
      });
  }

  modalConfirm(ok_cb, cancel_cb) {
    return this
      .setType(this.confirm_type)
      .setAnimationBounce(2)
      .setAnimation('RotateXR')
      .setCloseAnimation('right')
      .setBackgroundDismiss(false)
      .setButton('confirm', {
        text: 'Ok',
        action: typeof ok_cb !== 'function' ? () => {} : () => { ok_cb.call(this, this.data); },
      })
      .setButton('cancel', {
        text: 'Cancel',
        action: typeof cancel_cb !== 'function' ? () => {} : () => { cancel_cb.call(this, this.data); },
      });
  }

  setTitle(title) {
    this.title = title;

    return this;
  }

  setContent(content) {
    this.content = content;

    return this;
  }

  setColumnClass(column_class) {
    this.column_class = column_class;

    return this;
  }

  setContentCallback(cb) {
    this.on_content_cb = cb;

    return this;
  }

  /**
   * Set modal type
   * See: https://craftpip.github.io/jquery-confirm/#dialog-type
   *
   * @param type
   * @returns {ScaryJqueryConfirm}
   */
  setType(type) {
    this.type = type;

    return this;
  }

  setTypeAnimation(type_animation) {
    this.type_animation = type_animation;

    return this;
  }

  setDraggable(draggable) {
    this.draggable = draggable;

    return this;
  }

  /**
   * Add a button to the confirm modal
   * scope 'confirm':       set the callback
   * scope 'cancel':        set the callback
   * scope 'other_button':  set the button config
   * See: https://craftpip.github.io/jquery-confirm/#buttons
   *
   * @param scope
   * @param button_config
   * @returns {ScaryJqueryConfirm}
   */
  setButton(scope, button_config) {
    this.buttons[scope] = button_config;

    return this;
  }

  /**
   * Set animation type
   * See: http://craftpip.github.io/jquery-confirm/v3.3.2/animations.html
   *
   * @param animation
   * @returns {ScaryJqueryConfirm}
   */
  setAnimation(animation) {
    this.animation = animation;

    return this;
  }

  setCloseAnimation(animation) {
    this.close_animation = animation;

    return this;
  }

  setAnimationBounce(animation_bounce) {
    this.animation_bounce = animation_bounce;

    return this;
  }

  setAnimationSpeed(animation_speed) {
    this.animation_speed = animation_speed;

    return this;
  }

  /**
   * Set the behavior when the user clicks outside the modal
   * Default: do nothing
   * See: https://craftpip.github.io/jquery-confirm/#backgrounddismiss
   *
   * @param background_dismiss
   * @returns {ScaryJqueryConfirm}
   */
  setBackgroundDismiss(background_dismiss) {
    this.background_dismiss = background_dismiss;

    return this;
  }

  /**
   * Set custom data that can be retrieved at confirm/cancel time
   *
   * @param data
   * @returns {ScaryJqueryConfirm}
   */
  setData(data) {
    this.data = data;

    return this;
  }

  render() {

    $.confirm({
      title: this.title,
      content: this.content,
      onContentReady: this.on_content_cb.bind(this),
      columnClass: this.column_class,
      buttons: this.buttons,
      type: this.type,
      typeAnimated: this.type_animation,
      draggable: this.draggable,
      animation: this.animation,
      closeAnimation: this.close_animation,
      animationBounce: this.animation_bounce,
      animationSpeed: this.animation_speed,
      backgroundDismiss: this.background_dismiss,
    });
    
    return ``;
  }
}