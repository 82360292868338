import Component from "../../../../core/js/components/Component";

export default class ProductView extends Component{
    constructor(){
        super();
        this.data = undefined;
    }

    setData(data){
        this.data = data;

        return this;
    }

    render(){

        let accessoriTop = "<select>";
        accessoriTop += "<option selected>[Seleziona una voce]</option>";
        accessoriTop += "<option>FAX</option>";
        accessoriTop += "<option>Adf Dualscan (solo konica)</option>";
        accessoriTop += "</select>";

        let accessoriBase = "<select>";
        accessoriBase += "<option selected>[Seleziona una voce]</option>";
        accessoriBase += "<option>2 cassetti + tavolino</option>";
        accessoriBase += "<option>4 cassetti (3 + tavolino)</option>";
        accessoriBase += "</select>";

        let accessoriLatoDestro = "<select>";
        accessoriLatoDestro += "<option selected>[Seleziona una voce]</option>";
        accessoriLatoDestro += "</select>";

        let accessoriLatoSinistro = "<select>";
        accessoriLatoSinistro += "<option selected>[Seleziona una voce]</option>";
        accessoriLatoSinistro += "<option>Pinzatore</option>";
        accessoriLatoSinistro += "<option>Fascicolatore</option>";
        accessoriLatoSinistro += "</select>";
        
        let datiProd = this.data;

        //console.log(datiProd);
        //console.log("Marchio: " + datiProd.id_brand.name);

        let productHtml = "<div class='prodottoPr'>";
		productHtml += "<div class='imgPr'>";
		productHtml += "<img src='/assets/images/no-image.png' />";
		productHtml += "</div>";
		productHtml += "<div class='infoPr'>";
        productHtml += "<div class='brandPr'>" + datiProd.id_brand.name + "</div>";
		productHtml += "<div class='titoloPr'>" + datiProd.name + "</div>";
		productHtml += "<div class='skuPr'>" + datiProd.artcode_erp + "</div>";
        productHtml += "<div class='tipoPr'>" + datiProd.type + "</div>";
        productHtml += "<div class='formatoPr'>" + datiProd.format + "</div>";
		productHtml += "<div class='bloccoPr1'><hr></div>";
		productHtml += "<div class='desPr'>" + datiProd.description + "</div>";
        productHtml += "<div class='bloccoPr2'><hr></div>";
        productHtml += "<div class='accessoriPr'>";
        productHtml += "<div class='topPr'>" + accessoriTop + "</div>";
        productHtml += "<div class='basePr'>" + accessoriBase + "</div>";
        productHtml += "<div class='latoDestroPr'>" + accessoriLatoDestro + "</div>";
        productHtml += "<div class='latoSinistroPr'>" + accessoriLatoSinistro + "</div>";
        productHtml += "</div>";
        productHtml += "<div class='bloccoPr3'><hr></div>";
		productHtml += "<div class='prezzoPr'>" + datiProd.base_price + "&nbsp;&euro;</div>";
		productHtml += "<div class='offertaPr'>";
		productHtml += "<div class='qtaPr'>";
		productHtml += "<input type='text' value='1'><img class='menoPr' src='/assets/images/meno.png' /><img class='piuPr' src='/assets/images/piu.png' />";
		productHtml += "</div>";
		productHtml += "<div class='aggiungiPr'>";
        productHtml += "<div class='bottonePr'>";
        productHtml += "<a href='/offer/manage/create' title=\"Aggiungi all'Offerta\"><span>Aggiungi all'Offerta</span></a>";
        productHtml += "</div>";
        productHtml += "</div>";
		productHtml += "</div>";
		productHtml += "</div>";
		productHtml += "</div>";
	    productHtml += "</div>";

        return productHtml;
    }
}