const $ = window.jQuery;

require('datatables.net')(window, $);
require('datatables.net-autofill')(window, $);
require('datatables.net-buttons')(window, $);
require('datatables.net-buttons/js/buttons.colVis.js')(window, $);
require('datatables.net-buttons/js/buttons.html5.js')(window, $);
require('datatables.net-buttons/js/buttons.print.js')(window, $);
require('datatables.net-colreorder')(window, $);
require('datatables.net-fixedcolumns')(window, $);
require('datatables.net-fixedheader')(window, $);
require('datatables.net-keytable')(window, $);
require('datatables.net-rowgroup')(window, $);
require('datatables.net-rowreorder')(window, $);
require('datatables.net-responsive')(window, $);
require('datatables.net-scroller')(window, $);
require('datatables.net-searchbuilder')(window, $);
require('datatables.net-searchpanes')(window, $);
require('datatables.net-select')(window, $);
require('datatables.net-staterestore')(window, $);

import Component from "../../../../js/components/Component";

export default class ScaryDataTable extends Component {

  constructor() {
    super();

    this.name = 'datatables';
    this.name = this.getUniqId();
    this.fields = {};
    this.responsive_priorities = {};
    this.url = '';
    this.responsive_details = true;
    this.show_footer = true;
    this.select = false;
    this.on_select = false;
    this.on_deselect = false;
    this.language = 'en';
    this.translations = {
      en: {}
    };

    this.table = undefined;
  }

  addField(name, label) {
    this.fields[name] = label;

    return this;
  }

  setResponsivePriority(name, priority) {
    this.responsive_priorities[name] = priority;

    return this;
  }

  removeField(name) {
    this.fields[name] = undefined;

    return this;
  }

  _getHeaders() {
    const fields = [];
    const _this = this;
    Object.keys(this.fields).forEach(function (field) {
      const responsive_priority = typeof _this.responsive_priorities[field] !== 'undefined' ? " data-priority=\"" + _this.responsive_priorities[field] + "\""  : '';
      if (_this.fields[field]) {
        fields.push(`
          <th${responsive_priority}>${_this.fields[field]}</th>
        `);
      }
    });

    return fields.join('');
  }

  _getColumns() {
    const columns = [];
    const _this = this;
    Object.keys(this.fields).forEach(function (field) {
      if (_this.fields[field]) {
        columns.push({
          data: field,
          name: _this.fields[field],
        });
      }
    });

    return columns;
  }

  setUrl(url) {
    this.url = url;

    return this;
  }

  setResponsiveDetails(details) {
    this.responsive_details = details;

    return this;
  }

  setShowFooter(show) {
    this.show_footer = show;

    return this;
  }

  setSelect(select) {
    this.select = select;

    return this;
  }

  setOnSelect(on_select) {
    this.on_select = on_select;

    return this;
  }

  setOnDeselect(on_deselect) {
    this.on_deselect = on_deselect;

    return this;
  }

  getTable() {
    return this.table;
  }

  /**
   * Set a custom translation string
   * See https://datatables.net/manual/i18n
   *
   * @param field = translation object, look at lib/js/translation for available translations
   *
   * *OR*
   *
   * @param field
   * @param string
   * @param language = undefined (use default language)
   * @returns {ScaryDataTable}
   */
  setTranslation(field, string = undefined, language = undefined) {
    const lang = language || this.language;

    if (typeof field !== 'string') {
      this.translations[lang] = field;
    } else {
      this.translations[lang][field] = string;
    }

    return this;
  }

  setLanguage(language) {
    this.language = language;

    return this;
  }

  render() {
    const headers = this._getHeaders();
    const footer = this.show_footer ? '<tfoot><tr>' + headers + '</tr></tfoot>' : '';
    return `
      <table id="${this.name}" class="${this.classes.join(' ')}">
        <thead>
          <tr>
            ${headers}
          </tr>    
        </thead>
        ${footer}
      </table>
    `;
  }

  activate() {
    const responsive_details = this.responsive_details ? true : { details: false };
    this.table = $('table#' + this.name).DataTable({
      processing: true,
      serverSide: true,
      responsive: responsive_details,
      ajax: {
        url: API_URL + this.url,
        xhrFields: { withCredentials: true },
        error: function (data, textStatus, errorThrown) {
          $(document).trigger('message', [ 'error', data.responseJSON.error ]);
        },
      },
      destroy: true,
      columns: this._getColumns(),
      select: this.select,
      language: this.translations[this.language]
    });

    if (this.on_select) {
      this.table.on('select', this.on_select);
      this.table.on('deselect', this.on_deselect);
    }
  }
}