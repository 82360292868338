import $ from 'jquery';
import { default as App, classes } from '../core/js/App.js';
import AuthApi from "../core/js/api/AuthApi"; // this line is needed to load core's AuthApi
import RouterApi from "../core/js/api/RouterApi"; // this line is needed to load core's RouterApi
// Styling
import "../core/css/core.css"; // also imports css/style.css
// Import app's events
import "./js/events/general";
// Import pages
import "./js/pages/index";

$(function () {
  $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0" />');

  $('head').append('<link rel="icon" href="/assets/images/favicon.ico" />');
  // load bootstrap api calls to window namespace
  classes.RouterApi = RouterApi;
  classes.AuthApi = AuthApi;

  // Instantiate new app
  let app = new App();

  // start app
  app.bootstrap().then(function () {
    // App is started and routing has triggered the route
    // you can execute your own code here which is triggered after the first page load
  });
});