const $ = window.jQuery;

import Component from "../../../../core/js/components/Component";

export default class Accordion extends Component {
  
  constructor(){
    super();
    this.name = this.getUniqId();
    
    this.title = undefined;
    this.items = [];
  }
  
  addTitle(title){
    this.title = title;
    
    return this;
  }
  
  addItem(item){
    this.items.push(item);
    
    return this;
  }

  render(){
    let html = '';
    this.items.forEach(function(item){
      html += `<li class="item">
        <h4><label>${item.label}</label></h4>
        <section>${(typeof item.content === 'object' && typeof item.content.render === 'function') ? item.content.render() : item.content}</section>
      </li>`;
    });
    
    $(document).on('click', `#${this.name} li > h4`, function(){
      $(this).closest('.item').toggleClass('open').siblings().removeClass('open');
    });
    
    return `<div id="${this.name}" class="accordion-container ${this.classes.join(' ')}">
      ${typeof this.title === 'undefined' ? '' : '<header>' + this.title + '</header>'}
      <ul class="accordion">
        ${html}
      </ul>
    </div>`;
  }
  
}
