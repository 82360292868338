const $ = window.jQuery;

import Menu from "../../../core/js/components/Menu/Menu";
import MenuItem from "../../../core/js/components/Menu/MenuItem";

export default async function menu() {
  const menu_container = new Menu();
  menu_container.addUlClasses('menu');

  const dashboard = new MenuItem();
  dashboard
    .addWrapperClasses([ 'element', 'dashboard' ])
    .addClasses('left-menu')
    .setLink('/')
    .setTitle('Board');

  const offers = new MenuItem();
  offers
    .addWrapperClasses([ 'element', 'offerte' ])
    .addClasses('left-menu')
    .setLink('/offers')
    .setTitle('Offerte')
    .setRoles([ 'RD_ADMIN_OFFERS', 'RD_ADMIN_OWN_OFFERS' ]);

  const users = new MenuItem();
  users
    .addWrapperClasses([ 'element', 'utenti' ])
    .addClasses('left-menu')
    .setLink('/users')
    .setTitle('Utenti')
    .setRoles('RD_ADMIN_USERS');
	
  const customers = new MenuItem();
  customers
    .addWrapperClasses([ 'element', 'customers' ])
    .addClasses('left-menu')
    .setLink('/customers')
    .setTitle('Clienti')
    .setRoles([ 'RD_ADMIN_CUSTOMERS', 'RD_OWN_CUSTOMERS' ]);
	
  const products = new MenuItem();
  products
    .addWrapperClasses([ 'element', 'prodotti' ])
    .addClasses('left-menu')
    .setTitle('Prodotti')
    .setRoles([ 'RD_ADMIN_PRODUCTS' ]);
	
  const products_brands = new MenuItem();
  products_brands
    .addWrapperClasses([ 'element', 'marchi' ])
    .addClasses('left-menu')
    .setLink('/brands')
    .setTitle('Marchi')
    .setRoles([ 'RD_ADMIN_PRODUCTS']);

  const products_list = new MenuItem();
  products_list
      .addWrapperClasses([ 'element', 'prodotti' ])
      .addClasses('left-menu')
      .setLink('/products')
      .setTitle('Prodotti')
      .setRoles([ 'RD_ADMIN_PRODUCTS']);
	  
  const products_catalog = new MenuItem();
  products_catalog
      .addWrapperClasses([ 'element', 'catalogo' ])
      .addClasses('left-menu')
      .setLink('/products-catalog/' + DEFAULT_CATEGORY)
      .setTitle('Catalogo')
      .setRoles([ 'RD_ADMIN_PRODUCTS']);
	
  /*const menu_products = new Menu();
  menu_products
	.addUlClasses('submenu')
	.setItem(products);
	
  menu_products.addItems([
    products_brands,
    products_list
  ]);*/

  menu_container.addItems([
	dashboard,
	offers,
	users,
	customers,
	//menu_products,
	products_catalog,
    products_list,
	products_brands
  ]);

  $('nav').replaceWith(menu_container.render());

  return menu_container;
}
