import SubmitField from "../../../core/js/components/Form/SubmitField";

const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import TextField from "../../../core/js/components/Form/TextField";
import NumberField from "../components/Form/NumberField";
import PriceField from "../components/Form/PriceField";
import RolesApi from "../../../core/js/api/RolesApi";
import SelectField from "../../../core/js/components/Form/SelectField";
import OptionField from "../../../core/js/components/Form/OptionField";
import FileField from "../../../core/js/components/Form/FileField";
import ProductApi from "../api/ProductApi";
import ProductBrandsApi from "../api/ProductBrandsApi";
import HiddenField from "../../../core/js/components/Form/HiddenField";
import TextareaField from "../components/Form/TextareaField";
import ManyToManyForm from "../../../core/js/components/Form/ManyToManyForm";

export default class ProductFormPage extends Page {

  constructor() {
    super();

    this.roles = undefined;
    this.brands = undefined;

    this
      .setTitle('Gestione prodotti')
      .setMeta('description', 'Gestione prodotti')
      .setBreadcrumb([
        { label: 'Prodotti', href: '/products' },
        'Gestione prodotti'
      ]);
  }

  async init(product_id) {
    super.init();

    //inizio - ruoli
    const rolesApi = new RolesApi();

    this.roles = await rolesApi.roles().catch(function (e) {
      const errmsg = 'Errore nello scaricare i ruoli';
      $(document).trigger('message', [ 'error', errmsg ]);

      throw errmsg;
    });

    this.roles = this.roles.data;
    //fine - ruoli

    //inizio - marchi
    const productBrandsApi = new ProductBrandsApi();

    this.brands = await productBrandsApi.brands().catch(function (e) {
      const errmsg = 'Errore nello scaricare i marchi';
      $(document).trigger('message', [ 'error', errmsg ]);

      throw errmsg;
    });

    this.brands = this.brands.data;
    //fine - marchi
  }

  async content(product_id) {
    const productForm = new Form();
    const subProductForm = new ManyToManyForm();

    productForm
      .setAction('/product')
      .setTitle('Gestisci prodotto')
      .addClasses('padded-container label-left');
      //.addClasses('fullwidth label-left');
    let data;

    if (product_id === 'create') {
      // Create form
      productForm.setMethod('POST');
    } else {
      // Update form
      productForm.setMethod('PUT');

      const productApi = new ProductApi();
      const productData = await productApi.get(product_id);
      if (productData) {
        data = productData.data;
      } else {
        $(document).trigger('404', [ productApi.url + '/' + product_id ]);
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    const image = new FileField();
    image
      .setFieldName('image')
      .setPlaceholder('Inserisci una immagine')
      .setLabel('Immagine')
      .setMaxSize('2MB')
      .addMimeTypes(['jpg','png','jpeg']);

    const name = new TextField();
    name
      .setFieldName('name')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Nome');
    
    const artcode_erp = new TextField();
    artcode_erp
      .setFieldName('artcode_erp')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Codice ERP');

    const type = new TextField();
    type
      .setFieldName('type')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Tipologia');

    const description = new TextareaField();
    description
      .setFieldName('description')
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Descrizione Prodotto');
    
    const format = new TextField();
    format
      .setFieldName('format')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Formato');
    
    const available = new SelectField();
    available
      .setFieldName('available')
      .setLabel('Disponibile')
      .setRequired(true)
      .setMultiple(false);
    const optionsAva = [];

    const optionAva1 = new OptionField();
    optionAva1
      .setValue(0)
      .setLabel("No")
      .setDefault(data?.available === "0");
    const optionAva2 = new OptionField();
    optionAva2
      .setValue(1)
      .setLabel("Si")
      .setDefault(data?.available === "1");
    optionsAva.push(optionAva1);
    optionsAva.push(optionAva2);
    available.addOptions(optionsAva);

    const base_price = new PriceField();
    base_price
      .setFieldName('base_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('Prezzo Base');

    const transfer_price = new PriceField();
    transfer_price
      .setFieldName('transfer_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('Prezzo Vendita');

    const cc_bn_base_price = new PriceField();
    cc_bn_base_price
      .setFieldName('cc_bn_base_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Base BN');

    const cc_bn_transfer_price = new PriceField();
    cc_bn_transfer_price
      .setFieldName('cc_bn_transfer_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Vendita BN');

    const cc_color_base_price = new PriceField();
    cc_color_base_price
      .setFieldName('cc_color_base_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Base Colore');

    const cc_color_transfer_price = new PriceField();
    cc_color_transfer_price
      .setFieldName('cc_color_transfer_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Vendita Colore');

    const cc_light_base_price = new PriceField();
    cc_light_base_price
      .setFieldName('cc_light_base_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Base Leggero');

    const cc_light_transfer_price = new PriceField();
    cc_light_transfer_price
      .setFieldName('cc_light_transfer_price')
      .setMaxlength(50)
      .setRequired(true)
      .append("<span class='currency'>€</span>")
      .addWrapperClasses("price")
      .setPlaceholder('')
      .setLabel('CC Prezzo Vendita Leggero');

    const cc_max_bn = new NumberField();
    cc_max_bn
      .setFieldName('cc_max_bn')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Max Copie BN');

    const cc_max_bn_hidden = new HiddenField();
    cc_max_bn_hidden
      .setFieldName('cc_max_bn')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Max Copie BN');

    const cc_max_color = new NumberField();
    cc_max_color
      .setFieldName('cc_max_color')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Max Copie Colore');

    const cc_suggested_bn = new NumberField();
    cc_suggested_bn
      .setFieldName('cc_suggested_bn')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Copie BN Suggerite');

    const cc_suggested_color = new NumberField();
    cc_suggested_color
      .setFieldName('cc_suggested_color')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Copie Colore Suggerite');

    const weight = new NumberField();
    weight
      .setFieldName('weight')
      .setMaxlength(50)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Posizione in Categoria');

    const id_catalog = new HiddenField();
    id_catalog.setFieldName('id_catalog').setValue(DEFAULT_CATEGORY);

    const submit = new SubmitField();
    submit
      .setFieldName('submit_product')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    const cmbBrands = this.getSelectBrands(product_id !== 'create' ? data.id_brand.id : undefined);

    if (!data || data.relationship === 'product') {
      const cc_bn_base_price_hidden = new HiddenField();
      cc_bn_base_price_hidden.setFieldName('cc_bn_base_price').setValue(0);

      const cc_color_base_price_hidden = new HiddenField();
      cc_color_base_price_hidden.setFieldName('cc_color_base_price').setValue(0);

      const cc_bn_transfer_price_hidden = new HiddenField();
      cc_bn_base_price_hidden.setFieldName('cc_bn_base_price').setValue(0);

      const cc_color_transfer_price_hidden = new HiddenField();
      cc_color_transfer_price_hidden.setFieldName('cc_color_transfer_price').setValue(0);

      const cc_light_base_price_hidden = new HiddenField();
      cc_light_base_price_hidden.setFieldName('cc_light_base_price').setValue(0);

      const cc_light_transfer_price_hidden = new HiddenField();
      cc_light_transfer_price_hidden.setFieldName('cc_light_transfer_price').setValue(0);

      const cc_max_color_hidden = new HiddenField();
      cc_max_color_hidden.setFieldName('cc_max_color').setValue(0);

      const cc_suggested_bn_hidden = new HiddenField();
      cc_suggested_bn_hidden.setFieldName('cc_suggested_bn').setValue(0);

      const cc_suggested_color_hidden = new HiddenField();
      cc_suggested_color_hidden.setFieldName('cc_suggested_color').setValue(0);

      const subProductFields = [
        id_catalog.clone(),
        cmbBrands.clone(),
        image.clone(),
        name.clone(),
        artcode_erp.clone(),
        type.clone(),
        description.clone(),
        format.clone(),
        available.clone(),
        base_price.clone(),
        transfer_price.clone(),
        cc_bn_base_price_hidden,
        cc_bn_transfer_price_hidden,
        cc_color_base_price_hidden,
        cc_color_transfer_price_hidden,
        cc_light_base_price_hidden,
        cc_light_transfer_price_hidden,
        cc_max_bn_hidden,
        cc_max_color_hidden,
        cc_suggested_bn_hidden,
        cc_suggested_color_hidden,
        weight.clone()
      ];

      subProductForm
        .addFields(subProductFields)
        .setAddButton("<span class='btn_accessori'>Aggiungi</span>")
        .setDelButton("<span class='btn_accessori'>Cancella</span>")
        .setRelButton("<span class='btn_accessori'>Scegli Esistente</span>");
    }

    if (in_edit) {
      name.setValue(data.name);
      image.setValue(data.image);
      artcode_erp.setValue(data.artcode_erp);
      type.setValue(data.type);
      description.setValue(data.description);
      format.setValue(data.format);
      available.setValue(data.available);
      base_price.setValue(data.base_price);
      transfer_price.setValue(data.transfer_price);
      cc_bn_base_price.setValue(data.cc_bn_base_price);
      cc_bn_transfer_price.setValue(data.cc_bn_transfer_price);
      cc_color_base_price.setValue(data.cc_color_base_price);
      cc_color_transfer_price.setValue(data.cc_color_transfer_price);
      cc_light_base_price.setValue(data.cc_light_base_price);
      cc_light_transfer_price.setValue(data.cc_light_transfer_price);
      cc_max_bn.setValue(data.cc_max_bn);
      cc_max_color.setValue(data.cc_max_color);
      cc_suggested_bn.setValue(data.cc_suggested_bn);
      cc_suggested_color.setValue(data.cc_suggested_color);
      weight.setValue(data.weight);
    }

    const formFields = [
      id_catalog,
      submit.clone(),
      cmbBrands,
      name,
      image,
      artcode_erp,
      type,
      description,
      format,
      available,
      base_price,
      transfer_price,
      cc_bn_base_price,
      cc_bn_transfer_price,
      cc_color_base_price,
      cc_color_transfer_price,
      cc_light_base_price,
      cc_light_transfer_price,
      cc_max_bn,
      cc_max_color,
      cc_suggested_bn,
      cc_suggested_color,
      weight,
      submit
    ];

    if (!data || data.relationship === 'product') {
      formFields.push(subProductForm);
    }

    productForm.addFields(formFields);

    productForm.onSubmit(async function (res) {

      const productApi = new ProductApi();
      productApi.setForm(productForm);

      let values = productForm.getValues();

      let msg = '';
      if (in_edit) {
        const res = await productApi.put(product_id, values);
        msg = 'Prodotto modificato con successo';
      } else {
        const res = await productApi.post(values);
        product_id = res.data.id;
        msg = res.data.message;
      }

      if (!productForm.hasErrors()) {
        $(document).trigger('message', [ 'success', msg ]);
        $.spa.navigate('/product/manage/' + product_id, true);
      }
    });

    $('main .main').html(productForm.render());

    if (!data || data.relationship === 'product') {
      subProductForm.activate();
    }
  }

  getSelectBrands(defbrand) {
    const brandsSelect = new SelectField();
    brandsSelect
      .setFieldName('id_brand')
      .setRequired(true)
      .setLabel('Marchi')
      .setMultiple(false);
    const emptyopt = new OptionField();
    emptyopt
      .setValue('')
      .setLabel('Seleziona');

    brandsSelect.addOptions(emptyopt);
    const options = [];

    this.brands.map(function (brand) {
      const option = new OptionField();
      option
        .setValue(brand.id)
        .setLabel(brand.name);
      if (brand.id === defbrand) {
        option.setDefault(true);
      }

      options.push(option);
    });

    brandsSelect.addOptions(options);

    return brandsSelect;
  }
}