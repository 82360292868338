import FormField from "../../../../core/js/components/Form/FormField";

export default class TextareaField extends FormField {
  
  constructor() {
    super();
  
    this.setType('textarea');
  }
  
  render() {
    return this.wrap(`<textarea id="${this.name}" class="${this.classes.join(' ')}" name="${this.field_name}" placeholder="${this.placeholder}"${this.required ? ' required' : ''}${this.disabled ? ' disabled' : ''}${this.readonly ? ' readonly' : ''}>${this.value}</textarea>`);
  }
}
