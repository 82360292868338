const $ = window.jQuery;

import Api from "./Api";
import "../../lib/js/scary-router/src/jquery.spa";
import Init from "../utils/Init";
import Content from "../utils/Content";
import Exit from "../utils/Exit";

const routerUrl = '/client_router'; // Must be the same as RootRouter::$client_router_table on Serena API

export default class RouterApi extends Api {

  constructor() {
    super();

    this.url = routerUrl;
  }

  async call(cb) {
    return await this.__call(cb);
  }

  router() {
    this.setErrorCallback(this.routerError);
    this.method = 'GET';

    return this.call((res) => {
	    // Config spa plugin
      $.spa.configModule({
        historyConfig: {
          useHistoryApi: !HASHHISTORY
        }
      });

      // Set data in spa plugin
      $.spa.addRoutes(res.data);

      // Add scary phase classes
      $.spa.setPhasesClasses({
        Init,
        Content,
        Exit
      });

      return res;
    });
  }

  routerError(data, textStatus, errorThrown) {
    console.log(`Error ${data.status} retrieving ${APPNAME} router`);
    $(document).trigger('router-error', [ data.status ]);

    return data;
  }
}