import TextField from "../../../../core/js/components/Form/TextField";

export default class NumberField extends TextField {

  constructor() {
    super();

    this.setType('number');
	
    this.min = false;
	this.max = false;
    this.step = false;
  }
  
  setMin(min) {
    this.min = min;

    return this;
  }
  
  setMax(max) {
    this.max = max;

    return this;
  }
  
  setStep(step) {
    this.step = step;

    return this;
  }
  
  render() {
    const required = this.required ? ' required' : '';
    const disabled = this.disabled ? ' disabled' : '';
    const readonly = this.readonly ? ' readonly' : '';
    const min = this.min !== false ? ` min="${this.min}"` : '';
	const max = this.max !== false ? ` max="${this.max}"` : '';
    const step = this.step !== false ? ` step="${this.step}"` : '';
    return this.wrap(`<input class="${this.classes.join(' ')}" type="${this.type}" id="${this.name}" name="${this.field_name}" value="${this.value}" maxlength="${this.maxlength}" placeholder="${this.placeholder}"${required}${disabled}${readonly}${min}${max}${step}>`);
  }
}