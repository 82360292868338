const $ = window.jQuery;

import Api from "./Api";
import { vars } from "../App";
import "../../lib/js/scary-router/src/jquery.spa";

export default class AuthApi extends Api {

  constructor() {
    super();

    this.cb = undefined;
  }

  async call() {
    return await this.__call(this.cb);
  }

  login(email, password) {
    this.method = 'POST';
    this.url = '/login';
    this.data = {
      email,
      password
    };
    this.cb = this.loginAction.bind(this);
    this.setErrorCallback(this.loginError.bind(this));

    return this.call();
  }

  loginAction(data) {
    const _this = this;
    $('#scary').load('wrapper.html', {}, function () {
      // do login action
      window.PROFILE = _this.profile.bind(_this)();
    });

    return data;
  }

  loginError(data, textStatus, errorThrown) {
    // handle login errors
    if (data.statusCode === 401) {
      this.form.setErrors({
        _global: [
          data.error
        ]
      });
    } else {
      this.__errorCallback(data, textStatus, errorThrown);
    }

    return data;
  }

  logout() {
    this.method = 'GET';
    this.url = '/logout';
    this.cb = this.logoutAction.bind(this);
    this.setErrorCallback(this.logoutError.bind(this));

    return this.call();
  }

  logoutAction(data) {
    // do logout action
    window.PROFILE = undefined;

    return data;
  }

  logoutError(data, textStatus, errorThrown) {
    // handle logout errors

    return this.__errorCallback(data, textStatus, errorThrow);
  }

  profile() {
    this.method = 'GET';
    this.data = {};
    this.url = '/profile';
    this.cb = this.profileAction.bind(this);
    this.setErrorCallback(this.profileError.bind(this));

    return this.call();
  }

  profileAction(res) {
    // do profile action
    window.PROFILE = res.data;

    if (!vars.routerStarted) {
      // Start navigation only if the user is logged in
      $.spa.run();

      vars.routerStarted = true;
    } else {
      $.spa.navigate(window.location.pathname, true);
    }

    $(document).trigger('profile-loaded', [ res.data ]);

    return res;
  }

  profileError(data, textStatus, errorThrown) {
    // handle profile errors
    $(document).trigger('login');

    return data;
  }
}