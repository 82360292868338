import { gen, default as random_string } from "../../../core/js/utils/random_string";

const $ = window.jQuery;

$(function() {
  $(document).on('message', function (e, type, message) {
    const id = random_string(5, [ ...gen(65, 90), ...gen(97, 122) ]);
    const button_id = 'close-message-' + id;
    const close = type === 'errors_4xx' ? '' : '<button id="' + button_id + '">&#10006;</button>';
    $('#messages').append('<div class="message ' + type + '" style="display: none;" id="msg-' + id + '"><div class="msg-content">' + message + '</div>' + close + '</div>');
    $('#messages #msg-' + id).fadeIn();
    if (type !== 'errors_4xx') {
      $('#messages #' + button_id).on('click', function (e) {
        $(this).parent('.message').fadeOut();
      });
    } else {
      $('main .main').html('');
    }
  });
});