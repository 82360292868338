import BaseCrudApi from "./BaseCrudApi";

export default class UserRolesApi extends BaseCrudApi {

  constructor() {
    super();

    this.userId = undefined;
  }

  setUserId(user_id) {
    this.userId = user_id;
  }

  crudUrl() {
    return '/user/' + this.userId + '/roles';
  }

  postError(data, textStatus, errorThrown) {
    super.postError(data, textStatus, errorThrown);
    throw 'Cannot add role: ' + errorThrown;
  }

  deleteError(data, textStatus, errorThrown) {
    super.deleteError(data, textStatus, errorThrown);
    throw 'Cannot delete role: ' + errorThrown;
  }
}