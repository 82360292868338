const $ = window.jQuery;

import _ from "lodash";

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import SubmitField from "../../../core/js/components/Form/SubmitField";
import TextField from "../../../core/js/components/Form/TextField";
import TextareaField from "../components/Form/TextareaField";
import HiddenField from "../../../core/js/components/Form/HiddenField";
import SelectBooleanField from "../../../core/js/components/Form/SelectBooleanField";
import CustomerLocationApi from "../api/CustomerLocationApi";

export default class CustomerLocationFormPage extends Page {

  constructor() {
    super();
  }

  async init(customer_id) {

    let ragsoc = await this.getRagSocCustomer(customer_id);

    this
      .setTitle('Gestione Sede del Cliente')
      .setMeta('description', 'Gestione Sede del Cliente')
      .setBreadcrumb([
        { label: 'Clienti', href: '/customers' },
        { label: ragsoc, href: '/customer/manage/'+customer_id },
        { label: 'Elenco Sedi', href: '/customer/manage/'+customer_id+'/locations' },
        'Sede'
      ]);

    return super.init(customer_id);
  }

  async content(customer_id, location_id) {

    const customerLocationForm = new Form();
    customerLocationForm
      .setAction('/customer/manage/'+customer_id+'/location') 
      .setTitle('Gestisci Sedi del Cliente')
      .addClasses('padded-container label-left');
    let data;

    if (location_id === 'create') {
      // Create form
      customerLocationForm.setMethod('POST');
    } else {
      // Update form
      customerLocationForm.setMethod('PUT');

      const customerLocationsApi = new CustomerLocationApi();
      const customerLocationData = await customerLocationsApi.setCustomerId(customer_id).get(location_id);
      if (customerLocationData) {
        data = customerLocationData.data;
      } else {
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    this.setPageTitle(in_edit, customerLocationForm, 'Nuova Sede del Cliente', 'Modifica Sede del Cliente');

    const id_customer = new HiddenField();
    id_customer
      .setFieldName('id_customer')
      .setValue(customer_id);

    const name = new TextField();
    name
      .setFieldName('name')
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Nome');

    const address = new TextField();
        address
        .setFieldName('address')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Indirizzo');

    const city = new TextField();
        city
        .setFieldName('city')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Città');

    const province = new TextField();
        province
        .setFieldName('province')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Provincia');

    const country = new TextField();
        country
        .setFieldName('country')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Stato');

    const openingTime = new TextField();
    openingTime
      .setFieldName('opening_time')
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Orario di Apertura');

    const ztl = new SelectBooleanField();
    ztl
      .setFieldName('ztl')
      .setRequired(true)
      .setLabel("ZTL");

    const ref_name = new TextField();
        ref_name
        .setFieldName('ref_name')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel("Nome Referente");

    const ref_email = new TextField();
        ref_email
        .setFieldName('ref_email')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel("Email Referente");

    const ref_phone = new TextField();
        ref_phone
        .setFieldName('ref_phone')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel("Telefono Referente");

    const notes = new TextareaField();
        notes
        .setFieldName('notes')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel("Note");

    const submit = new SubmitField();
    submit
      .setFieldName('submit_location')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    if (in_edit) {
      name.setValue(data.name);
      address.setValue(data.address);
      city.setValue(data.city);
      province.setValue(data.province);
      country.setValue(data.province);
      openingTime.setValue(data.opening_time);
      ztl.setValue(data.ztl);
      ref_name.setValue(data.ref_name);
      ref_email.setValue(data.ref_email);
      ref_phone.setValue(data.ref_phone);
      notes.setValue(data.notes);
    }

    const formFields = [
      id_customer,
      name,
      address,
      city,
      province,
      country,
      openingTime,
      ztl,
      ref_name,
      ref_email,
      ref_phone,
      notes,
      submit,
    ];

    customerLocationForm.addFields(formFields);

    customerLocationForm.onSubmit(async function (res) {
      customerLocationForm.orig_values.roles = Array.isArray(customerLocationForm.orig_values.roles) ? customerLocationForm.orig_values.roles : [ customerLocationForm.orig_values.roles ];

      const customerLocationApi = new CustomerLocationApi();
      customerLocationApi.setForm(customerLocationForm);

      let values = customerLocationForm.getValues();

      let msg = '';
      if (in_edit) {
        const res = await customerLocationApi.setCustomerId(customer_id).put(location_id, values);
        msg = 'Sede Cliente modificata con successo';
      } else {
        const res = await customerLocationApi.setCustomerId(customer_id).post(values);
        location_id = res.data.id;
        msg = res.data.message;
        //msg = 'Sede Cliente inserita con successo';
      }

      if (!customerLocationForm.hasErrors()) {
          $(document).trigger('message', [ 'success', msg ]);
          $.spa.navigate('/customer/manage/'+customer_id+'/location/manage/' + location_id, true); 
      }
    });

    $('main .main').html(customerLocationForm.render());
  }
}