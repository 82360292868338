const $ = window.jQuery;

import _ from "lodash";

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import TextField from "../../../core/js/components/Form/TextField";
import SelectField from "../../../core/js/components/Form/SelectField";
import SubmitField from "../../../core/js/components/Form/SubmitField";
import OptionField from "../../../core/js/components/Form/OptionField";
import OrderApi from "../api/OrderApi";

export default class OrderFormPage extends Page {

  constructor() {
    super();
  }

  async init(order_id) {

    this
      .setTitle('Gestione ordine')
      .setMeta('description', 'Gestione ordine')
      .setBreadcrumb([
        { label: 'Ordini', href: '/orders' },
        'Gestione ordine'
      ]);

    return super.init(order_id);
  }

  async content(order_id) {
    const orderForm = new Form();
    orderForm
      .setAction('/orders')
      .setTitle('Gestisci ordini')
      .addClasses('padded-container');
    let data;

    if (order_id === 'create') {
      // Create form
      orderForm.setMethod('POST');
    } else {
      // Update form
      orderForm.setMethod('PUT');

      const orderApi = new OrderApi();
      const orderData = await orderApi.get(order_id);
      if (orderData) {
        data = orderData.data;
      } else {
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    this.setPageTitle(in_edit, orderForm, 'Nuovo Ordine', 'Modifica Ordine');

    const id = new TextField();
    id
      .setFieldName('id')
      .setRequired(true)
      .setPlaceholder('id');

    const num_ord = new TextField();
    num_ord
      .setFieldName('num_ord')
      .setRequired(true)
      .setPlaceholder('N° Ordine');

    const prodotti = new TextField();
    prodotti
      .setFieldName('prodotti')
      .setRequired(true)
      .setPlaceholder('Prodotti');

    const dur_contr = new SelectField();
    dur_contr
      .setFieldName('dur_contr')
      .setRequired(true);

    let durContrVal = ['12','24','36','48','60','72'];
    let durContrLbl = ['12','24','36','48','60','72'];

    this.addOptionsToSelectField(dur_contr,durContrVal.length,durContrVal,durContrLbl);

    const finanz = new SelectField();
    finanz
      .setFieldName('finanz')
      .setRequired(true);
    const finanzOptNO = new OptionField();
    finanzOptNO
      .setValue('noleggio operativo')
      .setLabel('Noleggio Operativo');
      finanz.addOptions([
        finanzOptNO
    ]);

    const sub_nol = new TextField();
    sub_nol
      .setFieldName('sub_nol')
      .setRequired(true)
      .setPlaceholder('Subentro a Noleggio');

    const sub_nol_note = new TextField();
    sub_nol_note
      .setFieldName('sub_nol_note')
      .setRequired(true)
      .setPlaceholder('Subentro a Noleggio Note');

    const sub_nol_topup = new TextField();
    sub_nol_topup
      .setFieldName('sub_nol_topup')
      .setRequired(true)
      .setPlaceholder('Subentro a Noleggio Top Up');

    const rit_usato = new TextField();
    rit_usato
      .setFieldName('rit_usato')
      .setRequired(true)
      .setPlaceholder('Ritiro Usato');

    const rit_usato_des = new TextField();
    rit_usato_des
      .setFieldName('rit_usato_des')
      .setRequired(true)
      .setPlaceholder('Ritiro Usato Descrizione');

    const rit_usato_valore = new TextField();
    rit_usato_valore
      .setFieldName('rit_usato_valore')
      .setRequired(true)
      .setPlaceholder('Ritiro Usato Valore');
  
    const num_cc_scel_mens = new TextField();
    num_cc_scel_mens
      .setFieldName('num_cc_scel_mens')
      .setRequired(true)
      .setPlaceholder('Numero Copie Scelte Mensili');
    
    const all_prev_firm_timbr = new TextField();
    all_prev_firm_timbr
      .setFieldName('all_prev_firm_timbr')
      .setRequired(true)
      .setPlaceholder('Allegato: Preventivo Firmato e Timbrato');

    const all_ci_patente = new TextField();
    all_ci_patente
      .setFieldName('all_ci_patente')
      .setRequired(true)
      .setPlaceholder('Allegato: C.I. o Patente');

    const all_tess_sanit = new TextField();
    all_tess_sanit
      .setFieldName('all_tess_sanit')
      .setRequired(true)
      .setPlaceholder('Allegato: Tessera Sanitaria');

    const tot_ord = new TextField();
    tot_ord
      .setFieldName('tot_ord')
      .setPlaceholder('Totale Ordine');

    const data_ord = new TextField();
    data_ord
      .setFieldName('data_ord')
      .setPlaceholder('Data Ordine');

    const submit = new SubmitField();
    submit
      .setFieldName('submit_order')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    if (in_edit) {
      id.setValue(data.id);
      num_ord.setValue(data.num_ord);
      prodotti.setValue(data.prodotti);
      dur_contr.setValue(data.dur_contr);
      finanz.setValue(data.finanz);
      sub_nol.setValue(data.sub_nol);
      sub_nol_note.setValue(data.sub_nol_note);
      sub_nol_topup.setValue(data.sub_nol_topup);
      rit_usato.setValue(data.rit_usato);
      rit_usato_des.setValue(data.rit_usato_des);
      rit_usato_valore.setValue(data.rit_usato_valore);
      num_cc_scel_mens.setValue(data.num_cc_scel_mens);
      all_prev_firm_timbr.setValue(data.all_prev_firm_timbr);
      all_ci_patente.setValue(data.all_ci_patente);
      all_tess_sanit.setValue(data.all_tess_sanit);
      tot_ord.setValue(data.tot_ord);
      data_ord.setValue(data.data_ord);
    }

    const formFields = [
      id,
      num_ord,
      prodotti,
      dur_contr,
      finanz,
      sub_nol,
      sub_nol_note,
      sub_nol_topup,
      rit_usato,
      rit_usato_des,
      rit_usato_valore,
      num_cc_scel_mens,
      all_prev_firm_timbr,
      all_ci_patente,
      all_tess_sanit,
      tot_ord,
      data_ord,
      submit,
    ];

    orderForm.addFields(formFields);

    orderForm.onSubmit(async function (res) {
      orderForm.orig_values.roles = Array.isArray(orderForm.orig_values.roles) ? orderForm.orig_values.roles : [ orderForm.orig_values.roles ];

      const orderApi = new OrderApi();
      orderApi.setForm(orderForm);

      let values = orderForm.getValues();

      let msg = '';
      if (in_edit) {
        const res = await orderApi.put(order_id, values);
        msg = 'Ordine modificato con successo';
      } else {
        const res = await orderApi.post(values);
        order_id = res.data.id;
        msg = res.data.message;
        //msg = 'Ordine inserito con successo';
      }

      if (!orderForm.hasErrors()) {
          $(document).trigger('message', [ 'success', msg ]);
          $.spa.navigate('/order/manage/' + order_id, true);
      }
    });

    $('main .main').html(orderForm.render());
  }
}