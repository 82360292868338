const $ = window.jQuery;

import _ from "lodash";

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import TextField from "../../../core/js/components/Form/TextField";
import SubmitField from "../../../core/js/components/Form/SubmitField";
import SelectField from "../../../core/js/components/Form/SelectField";
import SelectBooleanField from "../../../core/js/components/Form/SelectBooleanField";
import OptionField from "../../../core/js/components/Form/OptionField";
import OfferProductShippingApi from "../api/OfferProductShippingApi";
import NumberField from "../components/Form/NumberField";
import TextareaField from "../components/Form/TextareaField";

export default class OfferProductShippingFormPage extends Page {

  constructor() {
    super();
  }

  async init(offer_id, product_id) {

    this
      .setTitle('Gestione Spedizione del Prodotto dell\'Offerta')
      .setMeta('description', 'Gestione Spedizione del Prodotto dell\'Offerta')
      .setBreadcrumb([
        { label: 'Offerte', href: '/offers' },
        { label: 'Offerta', href: '/offer/manage/'+offer_id },
        { label: 'Prodotto', href: '/product/manage/'+product_id }, //il prodotto link punta alla pagina singola o a quella del prodotto dell'offerta?
        { label: 'Spedizioni', href: '/offer/manage/'+offer_id+'/product/manage/'+product_id+'/shippings' },
        'Spedizione'
      ]);

    return super.init(offer_id, product_id);
  }

  async content(offer_id, product_id, shipping_id) {

    const offerProductShippingForm = new Form();
    offerProductShippingForm
      .setAction('/offer/manage/'+offer_id+'/product/manage/'+product_id+'/shipping')
      .setTitle('Gestisci Spedizione del Prodotto dell\'Offerta')
      .addClasses('padded-container');
    let data;

    if (shipping_id === 'create') {
      // Create form
      offerProductShippingForm.setMethod('POST');
    } else {
      // Update form
      offerProductShippingForm.setMethod('PUT');

      const offerProductShippingApi = new OfferProductShippingApi();
      const offerProductShippingData = await offerProductShippingApi.setOfferId(offer_id).setProductId(product_id).get(shipping_id);
      if (offerProductShippingData) {
        data = offerProductShippingData.data;
      } else {
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    this.setPageTitle(in_edit, offerProductShippingForm, 'Nuova Spedizione Prodotto Offerta', 'Modifica Spedizione Prodotto Offerta');

    /*
    .addField('id', 'ID')
		.addField('id_offer', 'ID Offerta')
    */

    const ref_office = new TextField();
    ref_office
      .setFieldName('ref_office')
      .setRequired(true)
      .setPlaceholder('Ufficio di Riferimento');
    
    const delivery_times = new TextField();
    delivery_times
        .setFieldName('delivery_times')
        .setRequired(true)
        .setPlaceholder('Orari per la Consegna');

    const floor_delivery = new SelectBooleanField();
    floor_delivery
        .setFieldName('floor_delivery')
        .setRequired(true)
        .setLabel("Consegna al Piano");

    const floor_num = new TextField();
    floor_num
        .setFieldName('floor_num')
        .setRequired(true)
        .setPlaceholder('Piano di Consegna');

    const fe_present = new SelectBooleanField();
    fe_present
        .setFieldName('fe_present')
        .setRequired(true)
        .setLabel("Ascensore");

    const fe_width = new TextField();
    fe_width
        .setFieldName('fe_width')
        .setRequired(true)
        .setPlaceholder('Ascensore Larghezza Utile Porta');

    const fe_depth = new TextField();
    fe_depth
        .setFieldName('fe_depth')
        .setRequired(true)
        .setPlaceholder('Ascensore Profondità Interna');

    const st_num = new TextField();
    st_num
        .setFieldName('st_num')
        .setRequired(true)
        .setPlaceholder('Scala Numero Rampe');

    const st_width = new TextField();
    st_width
        .setFieldName('st_width')
        .setRequired(true)
        .setPlaceholder('Scala Larghezza');

    const st_depth = new TextField();
    st_depth
        .setFieldName('st_depth')
        .setRequired(true)
        .setPlaceholder('Scala Profondità');

    const st_type = new TextField();
    st_type
        .setFieldName('st_type')
        .setRequired(true)
        .setPlaceholder('Scala Tipologia');

    const internet = new SelectBooleanField();
    internet
        .setFieldName('internet')
        .setRequired(true)
        .setLabel("Presenza di Internet");

    //Tipologia di Rete
    const net_type = new SelectField();
    net_type
        .setFieldName('net_type')
        .setRequired(true);
    
    let netTypeVal = ['windows','altro'];
    let netTypeLbl = ['Windows','Altro'];

    this.addOptionsToSelectField(net_type,netTypeVal.length,netTypeVal,netTypeLbl);

    const net_domain = new SelectBooleanField();
    net_domain
        .setFieldName('net_domain')
        .setRequired(true)
        .setLabel("Rete a Dominio");

    const net_password = new SelectBooleanField();
    net_password
        .setFieldName('net_password')
        .setRequired(true)
        .setLabel("Password di Rete");

    const net_cable = new SelectField();
    net_cable
        .setFieldName('net_cable')
        .setRequired(true)
        .setLabel('Cavo di Rete');

    let netCableVal = ['no','1m','2m','3m','5m'];
    let netCableLbl = ['No','1m','2m','3m','5m'];

    this.addOptionsToSelectField(net_cable,netCableVal.length,netCableVal,netCableLbl);

    const rooms_ready = new SelectBooleanField();
    rooms_ready
        .setFieldName('rooms_ready')
        .setRequired(true)
        .setLabel("Locali Pronti");

    const num_pc = new NumberField();
    num_pc
        .setFieldName('num_pc')
        .setRequired(true)
        .setPlaceholder('Numero Postazioni PC');

    const num_mac = new NumberField();
    num_mac
        .setFieldName('num_mac')
        .setRequired(true)
        .setPlaceholder('Numero Postazioni MAC');

    const notes = new TextareaField();
    notes
        .setFieldName('notes')
        .setRequired(true)
        .setLabel('Note');

    const submit = new SubmitField();
    submit
      .setFieldName('submit_shipping')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    if (in_edit) {
      ref_office.setValue(data.ref_office);
      delivery_times.setValue(data.delivery_times);
      floor_delivery.setValue(data.floor_delivery);
      floor_num.setValue(data.floor_num);
      fe_present.setValue(data.fe_present);
      fe_width.setValue(data.fe_width);
      fe_depth.setValue(data.fe_depth);
      st_num.setValue(data.st_num);
      st_width.setValue(data.st_width);
      st_depth.setValue(data.st_depth);
      st_type.setValue(data.st_type);
      internet.setValue(data.internet);
      net_type.setValue(data.net_type);
      net_domain.setValue(data.net_domain);
      net_password.setValue(data.net_password);
      net_cable.setValue(data.net_cable);
      rooms_ready.setValue(data.rooms_ready);
      num_pc.setValue(data.num_pc);
      num_mac.setValue(data.num_mac);
      notes.setValue(data.notes);
    }

    const formFields = [
      ref_office,
      delivery_times,
      floor_delivery,
      floor_num,
      fe_present,
      fe_width,
      fe_depth,
      st_num,
      st_width,
      st_depth,
      st_type,
      internet,
      net_type,
      net_domain,
      net_password,
      net_cable,
      rooms_ready,
      num_pc,
      num_mac,
      notes,
      submit,
    ];

    offerProductShippingForm.addFields(formFields);

    offerProductShippingForm.onSubmit(async function (res) {
      offerProductShippingForm.orig_values.roles = Array.isArray(offerProductShippingForm.orig_values.roles) ? offerProductShippingForm.orig_values.roles : [ offerProductShippingForm.orig_values.roles ];

      const offerProductShippingApi = new OfferProductShippingApi();
      offerProductShippingApi.setForm(offerProductShippingForm);

      let values = offerProductShippingForm.getValues();

      let msg = '';
      if (in_edit) {
        const res = await OfferProductShippingApi.put(shipping_id, values);
        msg = 'Spedizione Prodotto Offerta modificata con successo';
      } else {
        const res = await OfferProductShippingApi.post(values);
        shipping_id = res.data.id;
        msg = res.data.message;
        //msg = 'Spedizione Prodotto Offerta inserita con successo';
      }

      if (!offerProductShippingForm.hasErrors()) {
          $(document).trigger('message', [ 'success', msg ]);
          $.spa.navigate('/offer/manage/'+offer_id+'/product/manage/'+product_id+'/shipping/manage/' + shipping_id, true);
      }
    });

    $('main .main').html(offerProductShippingForm.render());
  }
}