const $ = window.jQuery;
//require('jquery-modal');

//import AuthApi from "../../../core/js/api/AuthApi";
//import RolesApi from "../../../core/js/api/RolesApi";
//import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
//import ScaryJqueryModal from "../../../core/plugins/jquerymodal/js/components/ScaryJqueryModal";
//import ApiSelectField from "../../../core/js/components/Form/ApiSelectField";
import Page from "./Page";

export default class DashboardPage extends Page {

  constructor() {
    super();
  }

  async init() {

    this
      .setTitle('Dashboard')
      .setMeta('description', 'Dashboard for current user')
      .setBreadcrumb('Dashboard');

    return super.init();
  }

  content() {

    let ruolo;
    let firstRole = PROFILE.roles[0];

    //console.log("ruoli: " + PROFILE.roles);

    if(firstRole.indexOf('RD_ADMIN') != -1){
      ruolo = "Admin";
    }
    else if(firstRole.indexOf('RD_OWN') != -1){
      ruolo = "Agente";
    }

    // $(document).trigger('message', [ 'warning', 'Very looooooooooooooooooooooooooooooooooooong message with many words: testing wordwrap & more' ]);
    // const confirm = new ScaryJqueryConfirm();
    // confirm
    //   .setData({ ok: 1 })
    //   .setTitle('Prova confirm')
    //   .setContent('<strong>Contenuto HTML</strong> della confirm')
    //   // Put modalAlert or modalConfirm after setting content, specially data
    //   // .modalAlert(function (data) { console.log(this, data); }.bind(confirm))
    //   .modalConfirm(
    //     function (data) { console.log('ok', this, data); }.bind(confirm),
    //     function (data) { console.log('cancel', this, data); }.bind(confirm)
    //   )
    //   .render();
    
    //const modal = new ScaryJqueryModal();
    //$('main .main').html('<a id="prova">Prova</a>');
    
    /*modal
      .setId('prova')
      .setContent('<h1>Prova modal</h1>')
      .setAnchor($('#prova'))
      .install();*/
	  
    /*const apiselect = new ApiSelectField();
    apiselect
      .setUrl('/api_select/offer')
      .addStep(0, 'customer')
      .addStep(1, 'location')
      .addStep(2, 'shipping')
      .setCallback(function (value) {
        console.log(value);
      });*/

    //const htmlPrec = '<br>Benvenuto ' + ruolo + ',<br>' + PROFILE.first_name + ' '  + PROFILE.last_name + ' (' + PROFILE.email +') ';
	
    //html della pagina dashboard nuova "bozza"
    let htmlDashBoard = `<div class="profiloDsb">
		<div class="imageDsb">
			<img src="/assets/images/utente.png">
		</div>
		<div class="infoDsb">
			<h3>${PROFILE.first_name} ${PROFILE.last_name}</h3>
		</div>
		<div class="emailDsb">
			${PROFILE.email}
		</div>
		<div class="ruoliDsb">
			${ruolo}
		</div>
		<div class="logoutDsb">
			<a class="logoutLink pointer btn-logout">Esci</a>
		</div>
	</div>
	<div class="boxesDsb">
		<div class="preventiviDsb">
			OFFERTE INVIATE<br>
			<div class="numeri">4072</div>
		</div>
		<div class="attesaFinanzDsb">
			OFFERTE IN VALUTAZIONE<br>
			<div class="numeri">177</div>
		</div>
		<div class="contrattiDsb">
			PROVVIGIONI MATURATE<br>
			<div class="numeri">4.226,88 €</div>
		</div>
		<div class="provvigDsb" style="display:none">
			&nbsp;<br>
			<div class="numeri">&nbsp;</div>
		</div>
	</div>
	<div class="offerDsb">
		<div class="titoloBtnDsb">
			OFFERTE<br><br>
			<a href="/offer/manage/create"><button id="btnNuovaOfferta" name="btnNuovaOfferta" class="btnNewOffer">Nuova Offerta</button></a>
		</div>
		<div class="imgDsb">
			<img src="/assets/images/offerte-blu.png">
		</div>
	</div>`;

    //render
    $('main .main').html(htmlDashBoard);
	
	//apiselect.activate();
  }
}