const $ = window.jQuery;

import _ from "lodash";

import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import CustomerLocationApi from "../api/CustomerLocationApi";

export default class CustomerLocationsPage extends Page {

  constructor() {
    super();
  }

  async init(customer_id) {

	let ragsoc = await this.getRagSocCustomer(customer_id);

	this.setTitle('Sedi Cliente')
      .setMeta('description', 'Sedi Cliente')
	  .setBreadcrumb([
        { label: 'Clienti', href: '/customers'},{ label: ragsoc, href: '/customer/manage/'+customer_id },
        'Gestione Sedi'
      ]);

	return super.init(customer_id);
  }

  content(customer_id) {
	
	//
	// actions menu
	//

	//console.log("riga 36 - customer_id: " + customer_id);

    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-customer-locations']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_add = new ActionsMenuItem();
	btn_add
		.addClasses([ 'element', 'only-icon', 'add' ])
		.setTitle('Aggiungi')
		.setOn('click', () => $.spa.navigate('/customer/manage/'+customer_id+'/location/manage/create', true)) //??? QUESTO URL VA BENE ???
		.setRoles('RD_ADMIN_USERS');
		
	const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Modifica')
		.setRoles('RD_ADMIN_USERS');
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles('RD_ADMIN_USERS');
		
	actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);

	//
	// locations table
	//
	
	// $.confirm delete
	const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare la sede del cliente?');

	// quick-open dt row on double-click
	$(document).on('dblclick', '.dt-customer-locations tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/customer/manage/${customer_id}/location/manage/${targetId}`, true);
	});
		
	// handle dt_locations select/deselect
	const dt_locations_onSelectDeselect = function(e, dt, type, indexes){
		
		// check for selected rows
		let targetIds = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				targetIds.push(parseInt($(dt.row(idx).node()).find('td').first().text()));
			});
		}
		
		$(`#${btn_edit.name}, #${btn_delete.name}`)
			.off('click')
			.toggleClass('disabled', targetIds.length === 0);
		
		if(targetIds.length > 0){

			$(`#${btn_edit.name}`).on('click', () => $.spa.navigate('/customer/manage/'+customer_id+'/location/manage/'+targetIds[0], true));
			$(`#${btn_delete.name}`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const customerLocationApi = new CustomerLocationApi();
						await customerLocationApi.delete(targetIds[0])
							.then(() => {
								$.spa.navigate('/customer/manage/'+customer_id+'/locations', true);
							})
							.catch((e) => {
								$(document).trigger('message', [ 'error', e.responseJSON.error ]);
							});
					})
					.render();
			});
		}
		
	};
	const debounced_dt_locations_onSelectDeselect = _.debounce(dt_locations_onSelectDeselect, 100);
	
	//console.log("riga 114 - customer_id: " + customer_id);

	const dt_locations = new ScaryDataTable();
	dt_locations
		.setUrl('/customer/'+customer_id+'/location')
		.setSelect('single')
		.setOnSelect(debounced_dt_locations_onSelectDeselect)
		.setOnDeselect(debounced_dt_locations_onSelectDeselect)
		.addField('id', 'ID')
		//.addField('id_customer', 'Id Cliente')
		.addField('name', 'Nome')
		.addField('address', 'Indirizzo')
		.addField('city', 'Città')
		.addField('province', 'Provincia')
		.addField('country', 'Stato')
		.addField('opening_time', 'Orario')
		.addField('ztl', 'ZTL')
		.addField('ref_name', 'Ref. Nome')
		.addField('ref_email', 'Ref. Email')
		.addField('ref_phone', 'Ref. Tel.')
		.addField('notes', 'Note')
		.setResponsivePriority('id', 0)
		.setResponsivePriority('name', 1)
		.addClasses('dt-customer-locations');

	// render
    $('main .main').html(actions_menu.render() + dt_locations.render());
    dt_locations.activate();
  }
}