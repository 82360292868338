import DashboardPage from "./DashboardPage";
import UsersPage from "./UsersPage";
import UserFormPage from "./UserFormPage";
import BrandsPage from "./BrandsPage";
import BrandFormPage from "./BrandFormPage";
import ProductsPage from "./ProductsPage";
import ProductFormPage from "./ProductFormPage";
import ProductsCatalogPage from "./ProductsCatalogPage";
import SingleProductPage from "./SingleProductPage";
import OfferFormPage from "./OfferFormPage";
import CustomersPage from "./CustomersPage";
import CustomerFormPage from "./CustomerFormPage";
import CustomerLocationsPage from "./CustomerLocationsPage";
import CustomerLocationFormPage from "./CustomerLocationFormPage";
import OfferProductShippingsPage from "./OfferProductShippingsPage";
import OfferProductShippingFormPage from "./OfferProductShippingFormPage";
import OrdersPage from "./OrdersPage";
import OrderFormPage from "./OrderFormPage";

window.DashboardPage = DashboardPage;
window.UsersPage = UsersPage;
window.UserFormPage = UserFormPage;
window.BrandsPage = BrandsPage;
window.BrandFormPage = BrandFormPage;
window.ProductsPage = ProductsPage;
window.ProductFormPage = ProductFormPage;
window.ProductsCatalogPage = ProductsCatalogPage;
window.SingleProductPage = SingleProductPage;
window.OfferFormPage = OfferFormPage;
window.CustomersPage = CustomersPage;
window.CustomerFormPage = CustomerFormPage;
window.CustomerLocationsPage = CustomerLocationsPage;
window.CustomerLocationFormPage = CustomerLocationFormPage;
window.OfferProductShippingsPage = OfferProductShippingsPage;
window.OfferProductShippingFormPage = OfferProductShippingFormPage;
window.OrdersPage = OrdersPage;
window.OrderFormPage = OrderFormPage;