import FormField from "./FormField";

export default class FileField extends FormField {

  constructor() {
    super();

    this.name = 'input';
    this.type = 'file';
    this.maxsize = undefined;
    this.mimetypes = [];
  }

  setMaxSize(maxsize) {
    this.maxsize = maxsize;

    return this;
  }

  getMaxSize() {
    return this.maxsize;
  }

  addMimeTypes(mimetypes) {
    if (Array.isArray(mimetypes)) {
      const _this = this;
      mimetypes.forEach(function (mimetype) {
        _this.mimetypes.push(mimetype);
      });
    } else {
      this.mimetypes.push(mimetypes);
    }

    return this;
  }

  getMimeTypes() {
    return this.mimetypes;
  }

  getDefaultValue() {
    if (typeof this.getValue() !== 'string' || this.getValue().length === 0) {
      return '';
    }
    // handle view of default field value
    const extension = this.getValue().replace(/.*\.[^\.]*$/);
    let content = '';

    switch (extension) {
      // images
      case 'jpg':
      case 'jpeg':
      case 'png':
        content = `<img class="file-image" src="${this.getValue()}" />`;
        break;
      default:
        content = `<span class="file-nopreview">${this.getValue()}</span>`;
    }

    return content.length > 0 ? `<div class="file-content">${content}</div>` : '';
  }

  render() {
    const disabled = this.disabled ? ' disabled' : '';
    const maxsize = this.getMaxSize() ? `<span class="file-maxsize">${this.getMaxSize()}</span>` : '';
    const mimetypes = this.getMimeTypes() ? `<span class="file-mimetypes">${this.getMimeTypes().join(', ')}</span>` : '';
    const readonly = this.readonly ? ' readonly' : '';
    return this.wrap(
      this.getDefaultValue() +
      (this.placeholder.length > 0 ? `<span class="file-description">${this.placeholder}</span>` : '') +
      '<input class="' + this.classes.join(' ') + '" type="' + this.type + '" id="' + this.name + '" name="' +
      this.field_name + '"' + (this.required ? ' required' : '') + disabled + readonly + ' />' +
      '<div class="file-specs"' + maxsize + mimetypes + '</div>'
    );
  }
}