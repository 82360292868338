const $ = window.jQuery;

import Page from "./Page";
import GridList from "../../../core/js/components/View/GridList";
import SingleProductApi from "../api/SingleProductApi";
import ProductView from "../components/PageView/ProductView";

export default class SingleProductPage extends Page {

  constructor() {
    super();

    this
      .setTitle('Prodotto')
      .setMeta('description', 'Prodotto')
      .setBreadcrumb([
        { label: 'Catalogo', href: '/products-catalog/' + DEFAULT_CATEGORY },
        'Prodotto'
      ]);
  }

  async content(product_id) {

    const singleProductApi = new SingleProductApi();
    const response = await singleProductApi.get(product_id);
    
    if (response.data) {
      
      const pageView = new ProductView(response.data);
      pageView.setData(response.data);
      $('main .main').html(pageView.render());

    } else {
      return;
    }
    
  }

}