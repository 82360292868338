const $ = window.jQuery;

import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import _ from "lodash";
import OrderApi from "../api/OrderApi";

export default class OrdersPage extends Page {

  constructor() {
    super();
  }

  async init() {

    this.setTitle('Ordini')
      .setMeta('description', 'Ordini')
      .setBreadcrumb('Ordini');

    return super.init();
  }

  content() {
	
	//
	// actions menu
	//
	
    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-orders']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_add = new ActionsMenuItem();
	btn_add
		.addClasses([ 'element', 'only-icon', 'add' ])
		.setTitle('Aggiungi')
		.setOn('click', () => $.spa.navigate('/order/manage/create', true))
		.setRoles('RD_ADMIN_USERS');
		
	const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Modifica')
		.setRoles('RD_ADMIN_USERS');
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles('RD_ADMIN_USERS');
		
	actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);

	//
	// orders table
	//
	
	// $.confirm delete
	const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare l\'ordine ?');
		
	// quick-open dt row on double-click
	$(document).on('dblclick', '.dt-orders tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/order/manage/${targetId}`, true);
	});
		
	// handle dt_orders select/deselect
	const dt_orders_onSelectDeselect = function(e, dt, type, indexes){
		
		// check for selected rows
		let targetIds = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				targetIds.push(parseInt($(dt.row(idx).node()).find('td').first().text()));
			});
		}
		
		$(`#${btn_edit.name}, #${btn_delete.name}`)
			.off('click')
			.toggleClass('disabled', targetIds.length === 0);
		
		if(targetIds.length > 0){
			$(`#${btn_edit.name}`).on('click', () => $.spa.navigate(`/order/manage/${targetIds[0]}`, true));
			$(`#${btn_delete.name}`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const orderApi = new OrderApi();
						await orderApi.delete(targetIds[0])
							.then(() => {
								$.spa.navigate('/orders', true);
							})
							.catch((e) => {
								$(document).trigger('message', [ 'error', e.responseJSON.error ]);
							});
					})
					.render();
			});
		}
		
	};
	const debounced_dt_orders_onSelectDeselect = _.debounce(dt_orders_onSelectDeselect, 100);
	
	const dt_orders = new ScaryDataTable();
	dt_orders
		.setUrl('/order')
		.setSelect('single')
		.setOnSelect(debounced_dt_orders_onSelectDeselect)
		.setOnDeselect(debounced_dt_orders_onSelectDeselect)
		.addField('id', 'ID')
		.addField('num_ord', 'N° Ordine')
		.addField('prodotti', 'Prodotti')
		.addField('dur_contr', 'Durata Contratto')
		.addField('finanz', 'Finanziamento')
		.addField('sub_nol', 'Subentro a Noleggio')
		.addField('sub_nol_note', 'Subentro a Noleggio Note')
		.addField('sub_nol_topup', 'Subentro a Noleggio Top Up')
		.addField('rit_usato', 'Ritiro Usato')
		.addField('rit_usato_des', 'Ritiro Usato Descrizione')
		.addField('rit_usato_valore', 'Ritiro Usato Valore')
		.addField('num_cc_scel_mens', 'Numero Copie Scelte Mensili')
		.addField('all_prev_firm_timbr', 'Allegato: Preventivo Firmato e Timbrato')
		.addField('all_ci_patente', 'Allegato: C.I. o Patente')
		.addField('all_tess_sanit', 'Allegato: Tessera Sanitaria')
		.addField('tot_ord', 'Totale Ordine')
		.addField('data_ord', 'Data Ordine')
		.setResponsivePriority('id', 0)
		.setResponsivePriority('num_ord', 1)
		.setResponsivePriority('tot_ord', 2)
		.setResponsivePriority('data_ord', 3)
		.addClasses('dt-orders');

	// render
    $('main .main').html(actions_menu.render() + dt_orders.render());
    dt_orders.activate();
  }

}