import TextField from "./TextField";

export default class EmailField extends TextField {

  constructor() {
    super();

    this.setType('email');
  }

}