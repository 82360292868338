import Api from "../../../core/js/api/Api";

export default class ProductBrandsApi extends Api {
  constructor() {
    super(undefined, undefined, undefined);
  }


  async call(cb) {
    return await this.__call(cb);
  }

  async brands() {
    this.method = 'GET';
    this.url = '/brand';

    return await this.call((data) => {
      return data;
    });
  }
}
