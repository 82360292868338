import SubmitField from "../../../core/js/components/Form/SubmitField";

const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import BrandApi from "../api/BrandApi";
import TextField from "../../../core/js/components/Form/TextField";
import _ from "lodash";

export default class BrandFormPage extends Page {

  constructor() {
    super();
  }

  async init(brand_id) {

    this
      .setTitle('Gestione marchio')
      .setMeta('description', 'Gestione marchio')
      .setBreadcrumb([
        { label: 'Marchi', href: '/brands' },
        'Gestione marchio'
      ]);

    return super.init(brand_id);
  }

  async content(brand_id) {
    const brandForm = new Form();
    brandForm
      .setAction('/brand')
      .setTitle('Gestisci marchi')
      .addClasses('padded-container label-left');
    let data;

    if (brand_id === 'create') {
      // Create form
      brandForm.setMethod('POST');
    } else {
      // Update form
      brandForm.setMethod('PUT');

      const brandApi = new BrandApi();
      const brandData = await brandApi.get(brand_id);
      if (brandData) {
        data = brandData.data;
      } else {
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    this.setPageTitle(in_edit, brandForm, 'Nuovo Marchio', 'Modifica Marchio');

    const name = new TextField();
    name
      .setFieldName('name')
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Nome');

    const submit = new SubmitField();
    submit
      .setFieldName('submit_brand')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    if (in_edit) {
      name.setValue(data.name);
    }

    const formFields = [
      name,
      submit,
    ];

    brandForm.addFields(formFields);

    brandForm.onSubmit(async function (res) {
      brandForm.orig_values.roles = Array.isArray(brandForm.orig_values.roles) ? brandForm.orig_values.roles : [ brandForm.orig_values.roles ];

      const brandApi = new BrandApi();
      brandApi.setForm(brandForm);

      let values = brandForm.getValues();

      let msg = '';
      if (in_edit) {
        const res = await brandApi.put(brand_id, values);
        msg = 'Marchio modificato con successo';
      } else {
        const res = await brandApi.post(values);
        brand_id = res.data.id;
        msg = res.data.message;
        //msg = 'Marchio inserito con successo';
      }

      if (!brandForm.hasErrors()) {
          $(document).trigger('message', [ 'success', msg ]);
          $.spa.navigate('/brand/manage/' + brand_id, true);
      }
    });

    $('main .main').html(brandForm.render());
  }
}