import TextField from "../../../../core/js/components/Form/TextField";

export default class PriceField extends TextField {

  constructor() {
    super();

    this.setType("text");
  }
  
}
