const $ = window.jQuery;

import Page from "./Page";
import GridList from "../../../core/js/components/View/GridList";
import ProductsCatalogApi from "../api/ProductsCatalogApi";

export default class ProductsCatalogPage extends Page {

  constructor() {
    super();

    this
      .setTitle('Catalogo')
      .setMeta('description', 'Catalog dei Prodotti')
      .setBreadcrumb('Catalogo');
  }

  content(id_catalog) {
    const gridlist = new GridList();
    gridlist
      .setApi(new ProductsCatalogApi())
      .setDynamicParam(id_catalog)
      .setOrdering(["id","name","base_price"], "id", "asc")
      .addClasses("grid")
      .setCellCallback(function (record) {
        return `
          <div class="titolo"><a href="/product/view/${record.id}">${record.name}</a></div>
          <a class="img pointer" href="/product/view/${record.id}"><img src="/assets/images/no-image.png" /></a>
          <div class="info">
            <div class="prezzo">&euro; ${record.base_price}</div>
            <div class="offerta"><a href='/offer/manage/create' title=\"Aggiungi all'Offerta\"><img src="/assets/images/offerte.png" /></a></div> 
          </div>
        `;
      })
      .run()
      .then(function (gl) {
        $('main .main').html(gl.render());
      });
  }

}