import CrudApi from "../../../core/js/api/CrudApi";

export default class ProductsCatalogApi extends CrudApi {

  constructor() {
    super();

    this.setUrlSuffix('/product');
  }

  crudUrl() {
    return '/catalog';
  }
}
