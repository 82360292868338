import SubmitField from "../../../core/js/components/Form/SubmitField";

const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import UserApi from "../../../core/js/api/UserApi";
import TextField from "../../../core/js/components/Form/TextField";
import EmailField from "../../../core/js/components/Form/EmailField";
import PasswordField from "../../../core/js/components/Form/PasswordField";
import RolesApi from "../../../core/js/api/RolesApi";
import SelectField from "../../../core/js/components/Form/SelectField";
import OptionField from "../../../core/js/components/Form/OptionField";
import UserRolesApi from "../../../core/js/api/UserRolesApi";
import _ from "lodash";

export default class UserFormPage extends Page {

  constructor() {
    super();

    this.roles = undefined;
  }

  async init(user_id) {
    
    const rolesApi = new RolesApi();

    this.roles = await rolesApi.roles().catch(function (e) {
      const errmsg = 'Errore nello scaricare i ruoli';
      $(document).trigger('message', [ 'error', errmsg ]);

      throw errmsg;
    });

    this.roles = this.roles.data;

    this
      .setTitle('Gestione utente')
      .setMeta('description', 'Dashboard for current user')
      .setBreadcrumb([
        { label: 'Utenti', href: '/users' },
        'Gestione utente'
      ]);

    return super.init(user_id);
  }

  async content(user_id) {
    const userForm = new Form();
    userForm
      .setAction('/user')
      .setTitle('Gestisci utente')
      .addClasses('padded-container label-left');
    let data;

    if (user_id === 'create') {
      // Create form
      userForm.setMethod('POST');
    } else {
      // Update form
      userForm.setMethod('PUT');

      const userApi = new UserApi();
      const userData = await userApi.get(user_id);
      if (userData) {
        data = userData.data;
      } else {
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';

    this.setPageTitle(in_edit, userForm, 'Nuovo Utente', 'Modifica Utente');

    const email = new EmailField();
    email
      .setFieldName('email')
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Email');
    const password = new PasswordField();
    password
      .setFieldName('password')
      .setPlaceholder(in_edit ? 'Password (lascia vuoto per non cambiare)' : '')
      .setLabel('Password');
	if(!in_edit){
		password.setRequired(true);
	}
    const first_name = new TextField();
    first_name
      .setFieldName('first_name')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Nome');
    const last_name = new TextField();
    last_name
      .setFieldName('last_name')
      .setMaxlength(255)
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Cognome');
    const submit = new SubmitField();
    submit
      .setFieldName('submit_user')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    if (in_edit) {
      email.setValue(data.email);
      first_name.setValue(data.first_name);
      last_name.setValue(data.last_name);
    }

    //const subform = new Form();
    //subform
    //  .setTitle('SubForm');
    //const subformfield = new TextField();
    //subformfield
    //  .setFieldName('subformfield')
    //  .setMaxlength(255)
    //  .setRequired(true)
    //  .setPlaceholder('Subform field');
    //subform.addFields(subformfield);

    const formFields = [
      email,
      password,
      first_name,
      last_name,
      this.getSelectRoles(user_id !== 'create' ? data.roles : []),
      submit,
      //subform
    ];

    userForm.addFields(formFields);

    userForm.onSubmit(async function (res) {
      userForm.orig_values.roles = Array.isArray(userForm.orig_values.roles) ? userForm.orig_values.roles : [ userForm.orig_values.roles ];

      const userApi = new UserApi();
      userApi.setForm(userForm);

      let values = userForm.getValues();
      // Defer roles processing
      const roles = values.roles;

      let msg = '';
      if (in_edit) {
		values.roles = undefined;
        const res = await userApi.put(user_id, values);
        msg = 'Utente modificato con successo';
      } else {
        const res = await userApi.post(values);
        user_id = res.data.id;
        msg = res.data.message;
      }

      if (!userForm.hasErrors()) {
        // Set roles
		let err_roles = false;
		if(in_edit){
			let roles_difference = userForm.getDifference('roles');
			if (roles_difference) {
				const userRolesApi = new UserRolesApi();
				userRolesApi.setUserId(user_id);
				userForm.orig_values.roles.forEach(function (role) {
					if (roles.indexOf(role) === -1) {
					// Delete role
					userRolesApi.delete(role).catch(function (e) {
						err_roles = e;
					});
					} else {
					// Role not changed, remove it from processing roles
					_.remove(roles, rmrole => rmrole === role);
					}
				});
				roles.forEach(function (role) {
					// Add remaining roles
					userRolesApi.post({ role }).catch(function (e) {
					err_roles = e;
					});
				});
			}
		}
        if (!err_roles) {
          $(document).trigger('message', [ 'success', msg ]);
          $.spa.navigate('/user/manage/' + user_id, true);
        } else {
          $(document).trigger('message', [ 'error', 'Error setting roles for current user: ' + err_roles ]);
        }
      }
    });

    $('main .main').html(userForm.render());
  }

  getSelectRoles(defrole) {
    const rolesSelect = new SelectField();
    rolesSelect
      .setFieldName('roles')
      .setRequired(true)
      .setLabel('Ruoli')
      .setMultiple(true);
    const options = [];

    this.roles.map(function (role) {
      const defoption = defrole.indexOf(role) > -1;
      const option = new OptionField();
      option
        .setValue(role)
        .setLabel(role);
      if (defoption) {
        option.setDefault(true);
      }

      options.push(option);
    });

    rolesSelect.addOptions(options);

    return rolesSelect;
  }
}