import FormField from "./FormField";

export default class TextField extends FormField {

  constructor() {
    super();

    this.name = 'input';
    this.type = 'text';
    this.maxlength = 255;
  }

  setMaxlength(length) {
    this.maxlength = length;

    return this;
  }

  render() {
    const disabled = this.disabled ? ' disabled' : '';
    const readonly = this.readonly ? ' readonly' : '';
    return this.wrap('<input class="' + this.classes.join(' ') + '" type="' + this.type + '" id="' + this.name + '" name="' +
      this.field_name + '" value="' + this.value + '" maxlength="' + this.maxlength + '" placeholder="' +
      this.placeholder + '"' + (this.required ? ' required' : '') + disabled + readonly + ' />');
  }
}