const $ = window.jQuery;

import _ from "lodash";

import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import OfferProductShippingApi from "../api/OfferProductShippingApi";

export default class OfferProductShippingsPage extends Page {

  constructor() {
    super();
  }

  async init(offer_id, product_id) {

	this.setTitle('Spedizioni Prodotto Offerta')
      .setMeta('description', 'Spedizioni Prodotto Offerta')
	  .setBreadcrumb([
        { label: 'Offerte', href: '/offers'},
		{ label: 'Offerta', href: '/offer/manage/'+offer_id },
		{ label: 'Prodotto', href: '/product/manage/'+product_id }, //il link prodotto andrà al singolo prodotto o a quello legato all'offerta???
        'Gestione Spedizioni'
      ]);

	return super.init(offer_id, product_id);
  }

  content(offer_id, product_id) {
	
	//
	// actions menu
	//
	
    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-offer-product-shippings']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_add = new ActionsMenuItem();
	btn_add
		.addClasses([ 'element', 'only-icon', 'add' ])
		.setTitle('Aggiungi')
		.setOn('click', () => $.spa.navigate('/offer/manage/'+offer_id+'/product/manage/'+product_id+'/shipping/manage/create', true))
		.setRoles('RD_ADMIN_USERS');
		
	const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Modifica')
		.setRoles('RD_ADMIN_USERS');
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles('RD_ADMIN_USERS');
		
	actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);

	//
	// shippings table
	//
	
	// $.confirm delete
	const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare la spedizione del prodotto dell\'offerta ?');

	// quick-open dt row on double-click
	$(document).on('dblclick', '.dt-offer-product-shippings tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/offer/manage/${offer_id}/product/manage/${product_id}/shipping/manage/${targetId}`, true);
	});
		
	// handle dt_shippings select/deselect
	const dt_shippings_onSelectDeselect = function(e, dt, type, indexes){
		
		// check for selected rows
		let targetIds = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				targetIds.push(parseInt($(dt.row(idx).node()).find('td').first().text()));
			});
		}
		
		$(`#${btn_edit.name}, #${btn_delete.name}`)
			.off('click')
			.toggleClass('disabled', targetIds.length === 0);
		
		if(targetIds.length > 0){
			$(`#${btn_edit.name}`).on('click', () => $.spa.navigate('/offer/manage/'+offer_id+'/product/manage/'+product_id+'/shipping/manage/'+targetIds[0], true));
			$(`#${btn_delete.name}`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const offerProductShippingApi = new OfferProductShippingApi();
						await offerProductShippingApi.delete(targetIds[0])
							.then(() => {
								$.spa.navigate('/offer/manage/'+offer_id+'/product/manage/'+product_id+'/shippings', true);
							})
							.catch((e) => {
								$(document).trigger('message', [ 'error', e.responseJSON.error ]);
							});
					})
					.render();
			});
		}
		
	};
	const debounced_dt_shippings_onSelectDeselect = _.debounce(dt_shippings_onSelectDeselect, 100);
	
	const dt_shippings = new ScaryDataTable();
	dt_shippings
		.setUrl('/offer/'+offer_id+'/product/'+product_id+'/shipping')
		.setSelect('single')
		.setOnSelect(debounced_dt_shippings_onSelectDeselect)
		.setOnDeselect(debounced_dt_shippings_onSelectDeselect)
		.addField('id', 'ID')
		.addField('id_offer', 'ID Offerta')
		.addField('ref_office', 'Ufficio di Riferimento')
		.addField('delivery_times', 'Orari per la Consegna')
		.addField('floor_delivery', 'Consegna al Piano')
		.addField('floor_num', 'Piano di Consegna')
		.addField('fe_present', 'Ascensore')
		.addField('fe_width', 'Ascensore Larghezza Utile Porta')
		.addField('fe_depth', 'Ascensore Profondità Interna')
		.addField('st_num', 'Scala Numero Rampe')
		.addField('st_width', 'Scala Larghezza')
		.addField('st_depth', 'Scala Profondità')
		.addField('st_type', 'Scala Tipologia')
		.addField('internet', 'Presenza di Internet')
		.addField('net_type', 'Tipologia di Rete')
		.addField('net_domain', 'Rete a Dominio')
		.addField('net_password', 'Password di Rete')
		.addField('net_cable', 'Cavo di Rete')
		.addField('rooms_ready', 'Locali Pronti')
		.addField('num_pc', 'Num Postazioni PC')
		.addField('num_mac', 'Num Postazioni MAC')
		.addField('notes', 'Note')
		.setResponsivePriority('id', 0)
		.setResponsivePriority('id_offer', 1)
		.addClasses('dt-offer-product-shippings');

	// render
    $('main .main').html(actions_menu.render() + dt_shippings.render());
    dt_shippings.activate();
  }

}