const $ = window.jQuery;
import "../../../core/lib/js/scary-router/src/jquery.spa";

import { default as BasePage } from "../../../core/js/pages/Page";
import { vars } from "../../../core/js/App";
import CustomerApi from "../api/CustomerApi";
import OptionField from "../../../core/js/components/Form/OptionField";

export default class Page extends BasePage {
	
  init() {
    $('header .middle .header-breadcrumbs').html(this.getBreadcrumb());
    $('header .middle .header-breadcrumbs ul li:not(:last)').append('<span class="separator">&nbsp;</span>');
    $('#messages .errors_4xx').html('');
	
    // set "page-*" class on <body>
    if(typeof vars.url !== 'undefined'){
      $('body').addClass('page' + vars.url.replaceAll('/', '-'));
    }

    $('body').addClass('menu-open'); // TODO: check user selection
    $('.menu-toggler').off('click').on('click', function(){ $('body').toggleClass('menu-open'); });
	
    $('.user-menu-toggler').off('click').on('click', function(){ $('body').toggleClass('user-menu-open'); });
	
	  $('.page-refresh').off('click').on('click', this.refresh);

    // Init menu - set active trail
    $(window).trigger('init-menu', [ $.spa.currentRoute() ]);
  }
  
  exit() {
    // unset "page-*" class on <body>
    if(typeof vars.url !== 'undefined'){
      $('body').removeClass('page' + vars.url.replaceAll('/', '-'));
    }
  }

  setPageTitle(in_edit, form, titleNew, titleEdit){
    if(in_edit){
      form.setTitle(titleEdit);
    }
    else{
      form.setTitle(titleNew);
    }
  }

  async getRagSocCustomer(customer_id){
    let dataC;
    let ragsoc;

    const customerApi = new CustomerApi();
    const customerData = await customerApi.get(customer_id);
    if (customerData) {
      dataC = customerData.data;
      ragsoc = dataC.company_name;
    } else {
      return;
    }

    return ragsoc;
  }

  addOptionsToSelectField(select, num_options, values, labels){

    for(var o=0; o<num_options; o++){

      let opt = new OptionField();
      opt
        .setValue(values[o])
        .setLabel(labels[o]);

      select.addOptions(opt);

    }

  }

}