import SubmitField from "../../../core/js/components/Form/SubmitField";

const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
import TextField from "../../../core/js/components/Form/TextField";
import EmailField from "../../../core/js/components/Form/EmailField";
import HiddenField from "../../../core/js/components/Form/HiddenField";
import _ from "lodash";
import CustomerApi from "../api/CustomerApi";
import UserApi from "../api/UserApi";
import SelectField from "../../../core/js/components/Form/SelectField";
import OptionField from "../../../core/js/components/Form/OptionField";
import ButtonField from "../components/Form/ButtonField";

export default class CustomerFormPage extends Page {

  constructor() {
    super();

    this.users = undefined;

    
  }

  async init(customer_id) {
    
    //inizio - utenti
    const usersApi = new UserApi();

    this.users = await usersApi.collection().catch(function (e) {
      const errmsg = 'Errore nello scaricare gli utenti';
      $(document).trigger('message', [ 'error', errmsg ]);

      throw errmsg;
    });

    this.users = this.users.data;
    //fine - utenti

    this
      .setTitle('Gestione cliente')
      .setMeta('description', 'Gestione cliente')
      .setBreadcrumb([
        { label: 'Clienti', href: '/customers' },
        'Gestione cliente'
      ]);

    return super.init(customer_id);
  }

  async content(customer_id) {
    const customerForm = new Form();
    customerForm
      .setAction('/customer')
      .setTitle('Gestisci clienti')
      .addClasses('padded-container label-left');
    let data;

    if (customer_id === 'create') {
      // Create form
      customerForm.setMethod('POST');
    } else {
      // Update form
      customerForm.setMethod('PUT');

      const customerApi = new CustomerApi();
      const customerData = await customerApi.get(customer_id);
      if (customerData) {
        data = customerData.data;
      } else {
        return;
      }
    }

    const in_edit = typeof data !== 'undefined';
    
    this.setPageTitle(in_edit, customerForm, 'Nuovo Cliente', 'Modifica Cliente');

    let id_user;

    if(PROFILE.roles.indexOf('RD_ADMIN_CUSTOMERS') > -1){

      //console.log("id_user: " + data.id_user.id);

      id_user = this.getSelectUsers(customer_id !== 'create' ? data.id_user.id : undefined);
    }
    else{
      id_user = new HiddenField();
      id_user
      .setFieldName('id_user')
      .setValue(PROFILE.id);
    }

    const companyName = new TextField();
      companyName
      .setFieldName('company_name')
      .setRequired(true)
      .setPlaceholder('')
      .setLabel('Ragione Sociale');

    const activity = new SelectField();
    activity
        .setFieldName('activity')
        .setRequired(true)
        .setLabel('Tipo Attività');

    let activityVal = ['','company','pro','pa','firm','association'];
    let activityLbl = ['Seleziona','Azienda','Professionista','PA','Studio Associato','Associazione'];

    this.addOptionsToSelectField(activity,activityVal.length,activityVal,activityLbl);

    const address = new TextField();
        address
        .setFieldName('address')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Indirizzo e Civico');

    const city = new TextField();
        city
        .setFieldName('city')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Città');

    const province = new TextField();
        province
        .setFieldName('province')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Provincia');
    
    const country = new TextField();
        country
        .setFieldName('country')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Stato');

    const ref_offer = new TextField();
        ref_offer
        .setFieldName('ref_offer')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Referente Offerta');

    const ref_delivery = new TextField();
        ref_delivery
        .setFieldName('ref_delivery')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Referente Consegna');

    const ref_offer_phone = new TextField();
        ref_offer_phone
        .setFieldName('ref_offer_phone')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Referente Offerta Telefono');

    const ref_delivery_phone = new TextField();
        ref_delivery_phone
        .setFieldName('ref_delivery_phone')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('Referente Consegna Telefono');

    const vat = new TextField();
        vat
        .setFieldName('vat')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('P.IVA');

    const fiscalcode = new TextField();
        fiscalcode
        .setFieldName('fiscalcode')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('C.F.');

    const sdi = new TextField();
        sdi
        .setFieldName('sdi')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('SDI');

    const pec = new EmailField();
        pec
        .setFieldName('pec')
        .setRequired(true)
        .setPlaceholder('')
        .setLabel('PEC');

    const submit = new SubmitField();
    submit
      .setFieldName('submit_customer')
      .setValue(in_edit ? 'Aggiorna' : 'Salva');

    const btn_locations = new ButtonField();
    btn_locations
      .setFieldName('button_location')
      .setValue('Gestisci Sedi')
      .onClick('/customer/manage/'+customer_id+'/locations');

    if (in_edit) {
      companyName.setValue(data.company_name);
      activity.setValue(data.activity); //modificare questo edit per la select
      address.setValue(data.address);
      city.setValue(data.city);
      province.setValue(data.province);
      country.setValue(data.country);
      ref_offer.setValue(data.ref_offer);
      ref_delivery.setValue(data.ref_delivery);
      ref_offer_phone.setValue(data.ref_offer_phone);
      ref_delivery_phone.setValue(data.ref_delivery_phone);
      vat.setValue(data.vat);
      fiscalcode.setValue(data.fiscalcode);
      sdi.setValue(data.sdi);
      pec.setValue(data.pec);
      btn_locations.addClassHref('hrefButton is_visibile');
      btn_locations.addClasses(['btn_sedi','is_visibile']);
    }
    else{
      btn_locations.addClassHref('hrefButton is_invisibile');
      btn_locations.addClasses(['btn_sedi','is_invisibile']);
    }

    const formFields = [
      id_user,
      companyName,
      activity,
      address,
      city,
      province,
      country,
      ref_offer,
      ref_delivery,
      ref_offer_phone,
      ref_delivery_phone,
      vat,
      fiscalcode,
      sdi,
      pec,
      submit,
    ];

    customerForm.addFields(formFields);

    customerForm.onSubmit(async function (res) {
      customerForm.orig_values.roles = Array.isArray(customerForm.orig_values.roles) ? customerForm.orig_values.roles : [ customerForm.orig_values.roles ];

      const customerApi = new CustomerApi();
      customerApi.setForm(customerForm);

      let values = customerForm.getValues();

      let msg = '';
      if (in_edit) {
        const res = await customerApi.put(customer_id, values);
        msg = 'Cliente modificato con successo';
      } else {
        const res = await customerApi.post(values);
        customer_id = res.data.id;
        msg = res.data.message;
        //msg = 'Cliente inserito con successo';
      }

      if (!customerForm.hasErrors()) {
          $(document).trigger('message', [ 'success', msg ]);
          $.spa.navigate('/customer/manage/' + customer_id, true);
      }
    });

    //render
    $('main .main').html(btn_locations.render() + customerForm.render());
  }

  getSelectUsers(defuser) {
    const usersSelect = new SelectField();
    usersSelect
      .setFieldName('id_user')
      .setRequired(true)
      .setLabel('Agenti')
      .setMultiple(false);
    const emptyopt = new OptionField();
    emptyopt
      .setValue('')
      .setLabel('Seleziona');

      usersSelect.addOptions(emptyopt);
    const options = [];

    this.users.map(function (user) {
      const option = new OptionField();
      option
        .setValue(user.id)
        .setLabel(user.first_name + " " + user.last_name + " ("+ user.email +")");
      
      if (user.id === defuser) {
        option.setDefault(true);
      }

      options.push(option);
    });

    usersSelect.addOptions(options);

    return usersSelect;
  }
}