import CrudApi from "../../../core/js/api/CrudApi";

export default class CustomerLocationApi extends CrudApi {
  
  constructor() {
    super();

    this.customerId = undefined;
  }

  setCustomerId(customer_id) {
    this.customerId = customer_id;
    return this;
  }
  
  crudUrl() {
    return '/customer/'+this.customerId+'/location'; 
  }

}
  