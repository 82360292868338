const $ = window.jQuery;

import Page from "./Page";
import Form from "../../../core/js/components/Form/Form";
//import OfferApi from "../api/OfferApi";
import CustomerApi from "../api/CustomerApi";
import CustomerLocationApi from "../api/CustomerLocationApi";
import CustomerLocationShippingApi from "../api/CustomerLocationShippingApi";
import ProductApi from "../api/ProductApi";
import TextField from "../../../core/js/components/Form/TextField";
import SelectField from "../../../core/js/components/Form/SelectField";
import OptionField from "../../../core/js/components/Form/OptionField";
import SubmitField from "../../../core/js/components/Form/SubmitField";
import HiddenField from "../../../core/js/components/Form/HiddenField";
import NumberField from "../components/Form/NumberField";
import PriceField from "../components/Form/PriceField";
import ButtonField from "../components/Form/ButtonField";
import SelectBooleanField from "../../../core/js/components/Form/SelectBooleanField";
import TextareaField from "../components/Form/TextareaField";
import Accordion from "../components/Accordion/Accordion";
import Wizard from "../components/Wizard/Wizard";
//import ApiSelectField from "../../../core/js/components/Form/ApiSelectField";
//import FileField from "../components/Form/FileField";
import _ from "lodash";

export default class OfferFormPage extends Page {

	constructor(){
		super();
	
		this.data = false;
		this.customers = [];
		this.customerLocations = [];
		this.customerLocationShippings = [];
		this.financings = [];
		this.selectedProducts = [];
		this.wizard = false;
		//this.apiSelectCustomerLocationShipping = false;
	}

  async init(offer_id) {
	// retrieve all customers
	const customerApi = new CustomerApi();
	const customers = await customerApi.collection().catch(function(e){
		const errmsg = 'Non è stato possibile recuperare i clienti';
		$(document).trigger('message', [ 'error', errmsg ]);
		throw errmsg;
	});
	this.customers = customers.data;
	
    this
      .setTitle('Gestione offerta')
      .setMeta('description', 'Gestione offerta')
      .setBreadcrumb(['Offerte', 'Gestione offerta']);
	
	return super.init(offer_id);
  }

  async content(offer_id) {
    
    /*if(offer_id){
		const offerApi = new OfferApi();
		const offerData = await OfferApi.get(offer_id);
		this.data = offerData.data;
	}*/
    
    const in_edit = this.data !== false;
	
    // TODO: retrieve selected products from cart
    this.selectedProducts = [2,3];
	
	// cascade customer -> location -> shipping
    /*this.apiSelectCustomerLocationShipping = new ApiSelectField();
    this.apiSelectCustomerLocationShipping
		.setUrl('/api_select/offer')
		.addStep(0, 'customer')
		.stepOptions(0, console.log, undefined, console.log)
		.addStep(1, 'location')
		.stepOptions(1, console.log, undefined, console.log)
		.addStep(2, 'shipping')
		.stepOptions(2, console.log, undefined, console.log)
		.setCallback(function (value) {
			console.log(value);
		});*/

	this.wizard = new Wizard();
	this.wizard
		.addClasses('offer-container padded-container')
		.addScreen({label: 'Anagrafica', content: this.prepareStepCustomer()})
		.addScreen({label: 'Prodotti', content: await this.prepareStepProducts()})
		.addScreen({label: 'Prezzi', content: this.prepareStepCalc()})
		.addScreen({label: 'Durata e subentro', content: this.prepareStepLength()})
		.addScreen({label: 'Provvigioni', content: this.prepareStepCommission()});

    $('main .main').html(this.wizard.render());
	
	/*if(this.apiSelectCustomerLocationShipping){
		this.apiSelectCustomerLocationShipping.activate();
	}*/
	
	this.attachInteractions(true);
	
  }
	
	//
	// WIZARD STEPS
	//
	
	// STEP: customer
	prepareStepCustomer(){
	
		// customers
		const idCustomer = new SelectField();
		idCustomer
			.setFieldName('id_customer')
			.setRequired(true)
			.setLabel('Cliente');
		const customerOpts = [];
		const emptyOpt = new OptionField();
		emptyOpt
			.setValue('')
			.setLabel('Seleziona');
		customerOpts.push(emptyOpt);
		this.customers.map(function(customer){
			const option = new OptionField();
			option
				.setValue(customer.id)
				.setLabel(customer.company_name);
			customerOpts.push(option);
		});
		idCustomer.addOptions(customerOpts);
		
		const company_name = new TextField();
		company_name
			.setFieldName('company_name')
			.setLabel('Azienda')
			.setReadonly();
			
		const activity = new TextField();
		activity
			.setFieldName('activity')
			.setLabel('Attività')
			.setReadonly();

		const address = new TextField();
		address
			.setFieldName('address')
			.setLabel('Indirizzo')
			.setReadonly();
		
		const city = new TextField();
		city
			.setFieldName('city')
			.setLabel('Città')
			.setReadonly();
		
		const province = new TextField();
		province
			.setFieldName('province')
			.setLabel('Provincia')
			.setReadonly();
		
		const country = new TextField();
		country
			.setFieldName('country')
			.setLabel('Nazione')
			.setReadonly();
			
		const customerAddressFieldset = new Form();
		customerAddressFieldset
			.addClasses('fieldset-anagrafica-indirizzo fieldset-inline')
			.addFields([
				address,
				city,
				province,
				country
			]);
			
		const vat = new TextField();
		vat
			.setFieldName('vat')
			.setLabel('Partita IVA')
			.setReadonly();
			
		const fiscalcode = new TextField();
		fiscalcode
			.setFieldName('fiscalcode')
			.setLabel('Codice fiscale')
			.setReadonly();
			
		const sdi = new TextField();
		sdi
			.setFieldName('sdi')
			.setLabel('SDI')
			.setReadonly();
			
		const pec = new TextField();
		pec
			.setFieldName('pec')
			.setLabel('PEC')
			.setReadonly();
			
		const customerFiscalFieldset = new Form();
		customerFiscalFieldset
			.addClasses('fieldset-inline')
			.addFields([
				vat,
				fiscalcode,
				sdi,
				pec
			]);
		
		const ref_offer = new TextField();
		ref_offer
			.setFieldName('ref_offer')
			.setLabel('Referente offerta')
			.setReadonly();
			
		const ref_offer_phone = new TextField();
		ref_offer_phone
			.setFieldName('ref_offer_phone')
			.setLabel('Telefono referente offerta')
			.setReadonly();
			
		const ref_delivery = new TextField();
		ref_delivery
			.setFieldName('ref_delivery')
			.setLabel('Referente consegna')
			.setReadonly();
			
		const ref_delivery_phone = new TextField();
		ref_delivery_phone
			.setFieldName('ref_delivery_phone')
			.setLabel('Telefono referente consegna')
			.setReadonly();
		
		const customerRefFieldset = new Form();
		customerRefFieldset
			.addClasses('fieldset-inline')
			.addFields([
				ref_offer,
				ref_offer_phone,
				ref_delivery,
				ref_delivery_phone
			]);
		
		const customerFieldset = new Form();
		customerFieldset
			.setTitle('Dati anagrafici e di fatturazione')
			.addClasses('fieldset-anagrafica fullwidth label-left collapsible collapsed')
			.addFields([
				company_name,
				activity,
				customerAddressFieldset,
				customerFiscalFieldset,
				customerRefFieldset
			]);

		// set values
		if(this.data !== false){
			idCustomer.setValue(this.data.id_customer);
		}
		
		// TESTING
		/*const uploadField = new FileField();
		uploadField
			.setFieldName('upload');*/

		const next = new SubmitField();
		next
			.setFieldName('submit_customer')
			.addClasses('next')
			.setValue('Avanti');
			
		const formActionsFieldset = new Form();
		formActionsFieldset
			.addClasses('form-actions')
			.addFields([
				next
			]);

		const stepForm = new Form();
		stepForm
			.setAction('/')
			.setTitle('Anagrafica')
			.addClasses('fullwidth label-left')
			.addFields([
				//uploadField,
				//this.apiSelectCustomerLocationShipping,
				idCustomer,
				customerFieldset,
				formActionsFieldset
			]);
		
		return stepForm;
	}
	
	// STEP: products, location, shipping
	async prepareStepProducts(){
		
		const accordion = new Accordion();
		accordion
			.addTitle('Prodotti selezionati')
			.addClasses('products-accordion');
			
		for (const product_id of this.selectedProducts) {
			const productApi = new ProductApi();
			const productData = await productApi.get(product_id);
			if (productData) {
				productData.data.id = product_id;
				
				// TODO: product needs also "extra" prices
				productData.data.cc_bn_transfer_extra_price = productData.data.cc_bn_transfer_price;
				productData.data.cc_color_transfer_extra_price = productData.data.cc_color_transfer_price;
				productData.data.cc_light_transfer_extra_price = productData.data.cc_light_transfer_price;
				
				accordion.addItem({
					label: productData.data.name,
					content: this.prepareOfferRow(productData.data)
				});
			} else {
				console.log(`Product ${product_id} not found`);
			}
		}
		
		const prev = new ButtonField();
		prev
			.setFieldName('cancel_products')
			.addClassHref('prev')
			.setValue('Indietro');
		
		const next = new SubmitField();
		next
			.setFieldName('submit_products')
			.addClasses('next')
			.setValue('Avanti');
			
		const formActionsFieldset = new Form();
		formActionsFieldset
			.addClasses('form-actions')
			.addFields([
				prev,
				next
			]);
		
		const stepForm = new Form();
		stepForm
			.setAction('/')
			.addClasses('fullwidth')
			.addFields([
				formActionsFieldset
			]);
		
		return [accordion, stepForm];
	}
	
	// STEP: copies, prices, installments
	prepareStepCalc(){
		
		// total copies included
		const copiesIncludedTotalNumberBw = new NumberField();
		copiesIncludedTotalNumberBw
			.setFieldName('copies_included_total_number_bw')
			.setLabel('Numero copie B/N')
			.addClasses('copies-total')
			.setValue(0)
			.setReadonly();
		
		const copiesIncludedTotalPriceBw = new PriceField();
		copiesIncludedTotalPriceBw
			.setLabel('Costo unificato B/N')
			.setFieldName('copies_included_total_price_bw')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price')
			.addClasses('copies-price-total')
			.setValue(0)
			.setReadonly();
			
		const includedBwFieldset = new Form();
		includedBwFieldset
			.addClasses('c')
			.addFields([
				copiesIncludedTotalNumberBw,
				copiesIncludedTotalPriceBw
			]);
			
		const copiesIncludedTotalNumberColors = new NumberField();
		copiesIncludedTotalNumberColors
			.setFieldName('copies_included_total_number_colors')
			.setLabel('Numero copie a colori')
			.addClasses('copies-total')
			.setValue(0)
			.setReadonly();
			
		const copiesIncludedTotalPriceColors = new PriceField();
		copiesIncludedTotalPriceColors
			.setLabel('Costo unificato a colori')
			.setFieldName('copies_included_total_price_colors')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price')
			.addClasses('copies-price-total')
			.setValue(0)
			.setReadonly();
			
		const includedColorsFieldset = new Form();
		includedColorsFieldset
			.addClasses('fieldset-inline')
			.addFields([
				copiesIncludedTotalNumberColors,
				copiesIncludedTotalPriceColors
			]);
			
		const copiesIncludedTotalNumberLight = new NumberField();
		copiesIncludedTotalNumberLight
			.setFieldName('copies_included_total_number_light')
			.setLabel('Numero copie light')
			.addClasses('copies-total')
			.setValue(0)
			.setReadonly();
		
		const copiesIncludedTotalPriceLight = new PriceField();
		copiesIncludedTotalPriceLight
			.setLabel('Costo unificato light')
			.setFieldName('copies_included_total_price_light')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price')
			.addClasses('copies-price-total')
			.setValue(0)
			.setReadonly();
			
		const includedLightFieldset = new Form();
		includedLightFieldset
			.addClasses('fieldset-inline')
			.addFields([
				copiesIncludedTotalNumberLight,
				copiesIncludedTotalPriceLight
			]);
			
		const includedFieldset = new Form();
		includedFieldset
			.setTitle('Copie incluse')
			.addFields([
				includedBwFieldset,
				includedColorsFieldset,
				includedLightFieldset
			]);
		
		// total copies extra
		const copiesExtraTotalNumberBw = new HiddenField();
		copiesExtraTotalNumberBw
			.setFieldName('copies_extra_total_number_bw')
			.addClasses('copies-total')
			.setValue(1);
		
		const copiesExtraTotalBasePriceBw = new PriceField();
		copiesExtraTotalBasePriceBw
			.setLabel('Costo unificato B/N')
			.setFieldName('copies_extra_total_start_price_bw')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price formfield-start_price')
			.addClasses('copies-price-total')
			.setReadonly();
		
		const copiesExtraTotalMarginBw = new NumberField();
		copiesExtraTotalMarginBw
			.setLabel('Margine B/N')
			.setFieldName('copies_extra_total_margin_bw')
			.setMin(0)
			.setMax(100)
			.setStep(0.01)
			.append('<span class="percent">%</span>')
			.addWrapperClasses('percentage formfield-margin')
			.addClasses('copies-margin-total')
			.setValue(0);
			
		const copiesExtraTotalPriceBw = new PriceField();
		copiesExtraTotalPriceBw
			.setLabel('Costo finale per singola copia B/N')
			.setFieldName('copies_extra_total_price_bw')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price formfield-final_price')
			.addClasses('copies-price-final')
			.setValue(0);
			
		const extraBwFieldset = new Form();
		extraBwFieldset
			.addClasses('fieldset-price-with-margin fieldset-inline')
			.addFields([
				copiesExtraTotalNumberBw,
				copiesExtraTotalBasePriceBw,
				copiesExtraTotalMarginBw,
				copiesExtraTotalPriceBw
			]);
		
		const copiesExtraTotalNumberColors = new HiddenField();
		copiesExtraTotalNumberColors
			.setFieldName('copies_extra_total_number_colors')
			.addClasses('copies-total')
			.setValue(1);
			
		const copiesExtraTotalBasePriceColors = new PriceField();
		copiesExtraTotalBasePriceColors
			.setLabel('Costo unificato a colori')
			.setFieldName('copies_extra_total_start_price_colors')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price formfield-start_price')
			.addClasses('copies-price-total')
			.setReadonly();
			
		const copiesExtraTotalMarginColors = new NumberField();
		copiesExtraTotalMarginColors
			.setLabel('Margine a colori')
			.setFieldName('copies_extra_total_margin_colors')
			.setMin(0)
			.setMax(100)
			.setStep(0.01)
			.append('<span class="percent">%</span>')
			.addWrapperClasses('percentage formfield-margin')
			.addClasses('copies-margin-total')
			.setValue(0);
			
		const copiesExtraTotalPriceColors = new PriceField();
		copiesExtraTotalPriceColors
			.setLabel('Costo finale per singola copia a colori')
			.setFieldName('copies_extra_total_price_colors')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price formfield-final_price')
			.addClasses('copies-price-final')
			.setValue(0);
			
		const extraColorsFieldset = new Form();
		extraColorsFieldset
			.addClasses('fieldset-price-with-margin fieldset-inline')
			.addFields([
				copiesExtraTotalNumberColors,
				copiesExtraTotalBasePriceColors,
				copiesExtraTotalMarginColors,
				copiesExtraTotalPriceColors
			]);
		
		const copiesExtraTotalNumberLight = new HiddenField();
		copiesExtraTotalNumberLight
			.setFieldName('copies_extra_total_number_light')
			.addClasses('copies-total')
			.setValue(1);
		
		const copiesExtraTotalBasePriceLight = new PriceField();
		copiesExtraTotalBasePriceLight
			.setLabel('Costo unificato light')
			.setFieldName('copies_extra_total_start_price_light')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price formfield-start_price')
			.addClasses('copies-price-total')
			.setReadonly();
		
		const copiesExtraTotalMarginLight = new NumberField();
		copiesExtraTotalMarginLight
			.setLabel('Margine light')
			.setFieldName('copies_extra_total_margin_light')
			.setMin(0)
			.setMax(100)
			.setStep(0.01)
			.append('<span class="percent">%</span>')
			.addWrapperClasses('percentage formfield-margin')
			.addClasses('copies-margin-total')
			.setValue(0);
			
		const copiesExtraTotalPriceLight = new PriceField();
		copiesExtraTotalPriceLight
			.setLabel('Costo finale per singola copia light')
			.setFieldName('copies_extra_total_price_light')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price formfield-final_price')
			.addClasses('copies-price-final')
			.setValue(0);
			
		const extraLightFieldset = new Form();
		extraLightFieldset
			.addClasses('fieldset-price-with-margin fieldset-inline')
			.addFields([
				copiesExtraTotalNumberLight,
				copiesExtraTotalBasePriceLight,
				copiesExtraTotalMarginLight,
				copiesExtraTotalPriceLight
			]);
		
		const extraFieldset = new Form();
		extraFieldset
			.setTitle('Copie extra')
			.addFields([
				extraBwFieldset,
				extraColorsFieldset,
				extraLightFieldset
			]);
			
		// installments
		const installmentBase = new PriceField();
		installmentBase
			.setLabel('Rata mensile')
			.setFieldName('installment_base')
			.append('<span class="currency">€</span>')
			.setRequired(true)
			.addWrapperClasses('price formfield-start_price')
			.setReadonly();
		
		const installmentMargin = new NumberField();
		installmentMargin
			.setLabel('Margine su rata mensile')
			.setFieldName('installment_margin')
			.setMin(0)
			.setMax(100)
			.setStep(0.01)
			.append('<span class="percent">%</span>')
			.addWrapperClasses('percentage formfield-margin')
			.setValue(0);
		
		const installmentFinal = new PriceField();
		installmentFinal
			.setLabel('Rata mensile finale')
			.setFieldName('installment_final')
			.append('<span class="currency">€</span>')
			.setRequired(true)
			.addWrapperClasses('price formfield-final_price')
			.setValue(0);
			
		const installmentFieldset = new Form();
		installmentFieldset
			.setTitle('Rateizzazione')
			.addClasses('fieldset-inline fieldset-price-with-margin')
			.addFields([
				installmentBase,
				installmentMargin,
				installmentFinal
			]);
		
		// set values
		if(this.data !== false){
			copiesIncludedTotalPriceBw.setValue(this.data.copies_included_total_price_bw);
			copiesIncludedTotalPriceColors.setValue(this.data.copies_included_total_price_colors);
			copiesIncludedTotalNumberBw.setValue(this.data.copies_included_number_bw);
			copiesIncludedTotalNumberColors.setValue(this.data.copies_included_number_colors);
			copiesExtraTotalMarginBw.setValue(this.data.copies_extra_total_margin_bw);
			copiesExtraTotalPriceBw.setValue(this.data.copies_extra_total_price_bw);
			copiesExtraTotalMarginColors.setValue(this.data.copies_extra_total_margin_colors);
			copiesExtraTotalPriceColors.setValue(this.data.copies_extra_total_price_colors);
			copiesExtraTotalNumberBw.setValue(this.data.copies_extra_number_bw);
			copiesExtraTotalNumberColors.setValue(this.data.copies_extra_number_colors);
			installmentMargin.setValue(this.data.installment_margin);
			installment.setValue(this.data.installment);			
		}
		
		const prev = new ButtonField();
		prev
			.setFieldName('cancel_calc')
			.addClassHref('prev')
			.setValue('Indietro');
		
		const next = new SubmitField();
		next
			.setFieldName('submit_calc')
			.addClasses('next')
			.setValue('Avanti');
			
		const formActionsFieldset = new Form();
		formActionsFieldset
			.addClasses('form-actions')
			.addFields([
				prev,
				next
			]);
		
		// step form
		const stepForm = new Form();
		stepForm
			.setAction('/')
			.setTitle('Prezzi')
			.addClasses('fullwidth label-left')
			.addFields([
				includedFieldset,
				installmentFieldset,
				extraFieldset,
				formActionsFieldset
			]);
		
		return stepForm;
	}

    // STEP: length, takeover, pickup
	prepareStepLength(){
		
		// contract
		const contractLength = new SelectField();
		contractLength
			.setFieldName('contract_length')
			.setLabel('Durata');
		const contractLengthOpt24 = new OptionField();
		contractLengthOpt24
			.setValue('24')
			.setLabel('24 mesi')
			.setDefault(this.data?.contract_length === '24');
		const contractLengthOpt36 = new OptionField();
		contractLengthOpt36
			.setValue('36')
			.setLabel('36 mesi')
			.setDefault(this.data?.contract_length === '36');
		const contractLengthOpt48 = new OptionField();
		contractLengthOpt48
			.setValue('48')
			.setLabel('48 mesi')
			.setDefault(this.data?.contract_length === '48');
		const contractLengthOpt60 = new OptionField();
		contractLengthOpt60
			.setValue('60')
			.setLabel('60 mesi')
			.setDefault(this.data?.contract_length === '60');
		const contractLengthOpt72 = new OptionField();
		contractLengthOpt72
			.setValue('72')
			.setLabel('72 mesi')
			.setDefault(this.data?.contract_length === '72');
		contractLength.addOptions([
			contractLengthOpt24,
			contractLengthOpt36,
			contractLengthOpt48,
			contractLengthOpt60,
			contractLengthOpt72
		]);
		
		const contractFieldset = new Form();
		contractFieldset
			.setTitle('Contratto')
			.addFields([
				contractLength
			]);
		
		// takeover    
		const takeover = new SelectBooleanField();
		takeover
			.setFieldName('takeover')
			.setLabel('Si effettua il subentro');
		
		const takeoverNotes = new TextareaField();
		takeoverNotes
			.setFieldName('takeover_notes')
			.setLabel('Note subentro');
		
		const takeoverTopup = new TextField();
		takeoverTopup
			.setFieldName('takeover_topup')
			.setLabel('Top up subentro');
		
		const takeoverFieldset = new Form();
		takeoverFieldset
			.setTitle('Subentro a noleggio')
			.addFields([
				takeover,
				takeoverNotes,
				takeoverTopup
			]);
		
		// pickup
		const pickup = new SelectBooleanField();
		pickup
			.setFieldName('pickup')
			.setLabel('Si effettua il ritiro');
		
		const pickupDescription = new TextareaField();
		pickupDescription
			.setFieldName('pickup_description')
			.setLabel('Descrizione dell’usato');
		
		const pickupValue = new PriceField();
		pickupValue
			.setFieldName('pickup_value')
			.setLabel('Valore dell’usato')
			.append('<span class="currency">€</span>')
			.addWrapperClasses('price');
		
		const pickupFieldset = new Form();
		pickupFieldset
			.setTitle('Ritiro dell’usato')
			.addFields([
				pickup,
				pickupDescription,
				pickupValue
			]);
		
		// set values
		if(this.data !== false){
			contractLength.setValue(this.data.contract_length);
			takeover.setValue(this.data.takeover);
			takeoverNotes.setValue(this.data.takeover_notes);
			takeoverTopup.setValue(this.data.takeover_topup);
			pickup.setValue(this.data.pickup);
			pickupDescription.setValue(this.data.pickup_description);
			pickupValue.setValue(this.data.pickup_value);
		}
		
		const prev = new ButtonField();
		prev
			.setFieldName('cancel_length')
			.addClassHref('prev')
			.setValue('Indietro');
		
		const next = new SubmitField();
		next
			.setFieldName('submit_length')
			.addClasses('next')
			.setValue('Avanti');
			
		const formActionsFieldset = new Form();
		formActionsFieldset
			.addClasses('form-actions')
			.addFields([
				prev,
				next
			]);

		const stepForm = new Form();
		stepForm
			.setAction('/')
			.setTitle('Durata e Subentro')
			.addClasses('fullwidth label-left')
			.addFields([
				contractFieldset,
				takeoverFieldset,
				pickupFieldset,
				formActionsFieldset
			]);
		
		return stepForm;
	}

	// STEP: commission review
	prepareStepCommission(){
		
		const prev = new ButtonField();
		prev
			.setFieldName('cancel_length')
			.addClassHref('prev')
			.setValue('Indietro');
			
		const submitOffer = new SubmitField();
		submitOffer
			.setFieldName('submit_commission')
			.addClasses('submit')
			.setValue('Salva');
			
		const formActionsFieldset = new Form();
		formActionsFieldset
			.addClasses('form-actions')
			.addFields([
				prev,
				submitOffer
			]);
		
		const stepForm = new Form();
		stepForm
			.setAction('/')
			.setTitle('Provvigioni')
			.addClasses('fullwidth label-left wizard-last')
			.addFields([
				formActionsFieldset
			]);
		
		return stepForm;
	}

	//
	// OFFER ROW
	//
	
	// single offer row
	prepareOfferRow(product){
		
		// id
		const idProduct = new HiddenField();
		idProduct
			.setFieldName('id_product')
			.setValue(product.id);
			
		// location and its fields (read-only)
		const location = new SelectField();
		location
			.setFieldName('id_location')
			.setRequired(true)
			.setLabel('Sede');
		const locationOpts = [];
		const emptyOpt = new OptionField();
		emptyOpt
			.setValue('')
			.setLabel('Seleziona');
		locationOpts.push(emptyOpt);    
		this.customerLocations.map(function(location){
			const option = new OptionField();
			option
				.setValue(location.id)
				.setLabel(location.name);
			locationOpts.push(option);
		});
		location.addOptions(locationOpts);
		
		const locationAddress = new TextField();
		locationAddress
			.setFieldName('address')
			.setLabel('Indirizzo')
			.setReadonly();
		
		const locationCity = new TextField();
		locationCity
			.setFieldName('city')
			.setLabel('Città')
			.setReadonly();
		
		const locationProvince = new TextField();
		locationProvince
			.setFieldName('province')
			.setLabel('Provincia')
			.setReadonly();
		
		const locationCountry = new TextField();
		locationCountry
			.setFieldName('country')
			.setLabel('Nazione')
			.setReadonly();
			
		const locationAddressFieldset = new Form();
		locationAddressFieldset
			.addClasses('fieldset-inline fieldset-location-address')
			.addFields([
				locationAddress,
				locationCity,
				locationProvince,
				locationCountry
			]);
		
		const locationOpeningTime = new TextField();
		locationOpeningTime
			.setFieldName('opening_time')
			.setLabel('Orari di apertura')
			.setReadonly();
		
		const locationZtl = new SelectBooleanField();
		locationZtl
			.setFieldName('ztl')
			.setLabel('ZTL')
			.setDisabled();
			
		const locationRefName = new TextField();
		locationRefName
			.setFieldName('ref_name')
			.setLabel('Nome referente')
			.setReadonly();
			
		const locationRefEmail = new TextField();
		locationRefEmail
			.setFieldName('ref_email')
			.setLabel('Email referente')
			.setReadonly();
			
		const locationRefPhone = new TextField();
		locationRefPhone
			.setFieldName('ref_phone')
			.setLabel('Telefono referente')
			.setReadonly();
			
		const locationRefFieldset = new Form();
		locationRefFieldset
			.addClasses('fieldset-inline fieldset-location-ref')
			.addFields([
				locationRefName,
				locationRefEmail,
				locationRefPhone
			]);
			
		const locationNotes = new TextareaField();
		locationNotes
			.setFieldName('notes')
			.setLabel('Note')
			.setReadonly();
		
		const locationFieldset = new Form();
		locationFieldset
			.setTitle('Sede')
			.addFields([
				location,
				locationAddressFieldset,
				locationOpeningTime,
				locationZtl,
				locationRefFieldset,
				locationNotes
			]);
		
		// copies included
		const copiesIncludedFieldsetFields = [];
		if(product.cc_bn_transfer_price > 0) // bn
		{
			const copiesIncludedNumberBw = new NumberField();
			copiesIncludedNumberBw
				.setFieldName('copies_included_number_bw')
				.setLabel('Numero copie B/N')
				.addClasses('copies-single')
				.setValue(0);
			
			const copiesIncludedPriceBw = new HiddenField();
			copiesIncludedPriceBw
				.setFieldName('copies_included_price_bw')
				.addClasses('copies-price-single')
				.setValue(product.cc_bn_transfer_price);
				
			copiesIncludedFieldsetFields.push(copiesIncludedNumberBw);
			copiesIncludedFieldsetFields.push(copiesIncludedPriceBw);
		}
		else
		{
			const copiesIncludedNumberBw = new HiddenField();
			copiesIncludedNumberBw
				.setFieldName('copies_included_number_bw')
				.setValue(0);
			
			const copiesIncludedPriceBw = new HiddenField();
			copiesIncludedPriceBw
				.setFieldName('copies_included_price_bw')
				.setValue(product.cc_bn_transfer_price);
				
			copiesIncludedFieldsetFields.push(copiesIncludedNumberBw);
			copiesIncludedFieldsetFields.push(copiesIncludedPriceBw);
		}
			
		if(product.cc_color_transfer_price > 0) // color
		{
			const copiesIncludedNumberColors = new NumberField();
			copiesIncludedNumberColors
				.setFieldName('copies_included_number_colors')
				.setLabel('Numero copie a colori')
				.addClasses('copies-single')
				.setValue(0);
				
			const copiesIncludedPriceColors = new HiddenField();
			copiesIncludedPriceColors
				.setFieldName('copies_included_price_colors')
				.addClasses('copies-price-single')
				.setValue(product.cc_color_transfer_price);
				
			copiesIncludedFieldsetFields.push(copiesIncludedNumberColors);
			copiesIncludedFieldsetFields.push(copiesIncludedPriceColors);
		}
		else
		{
			const copiesIncludedNumberColors = new HiddenField();
			copiesIncludedNumberColors
				.setFieldName('copies_included_number_colors')
				.setValue(0);
				
			const copiesIncludedPriceColors = new HiddenField();
			copiesIncludedPriceColors
				.setFieldName('copies_included_price_colors')
				.setValue(product.cc_color_transfer_price)
				.setReadonly();
				
			copiesIncludedFieldsetFields.push(copiesIncludedNumberColors);
			copiesIncludedFieldsetFields.push(copiesIncludedPriceColors);
		}
		
		if(product.cc_light_transfer_price > 0) // light
		{
			const copiesIncludedNumberLight = new NumberField();
			copiesIncludedNumberLight
				.setFieldName('copies_included_number_light')
				.setLabel('Numero copie light')
				.addClasses('copies-single')
				.setValue(0);
			
			const copiesIncludedPriceLight = new HiddenField();
			copiesIncludedPriceLight
				.setFieldName('copies_included_price_light')
				.addClasses('copies-price-single')
				.setValue(product.cc_light_transfer_price);
				
			copiesIncludedFieldsetFields.push(copiesIncludedNumberLight);
			copiesIncludedFieldsetFields.push(copiesIncludedPriceLight);
		}
		else
		{
			const copiesIncludedNumberLight = new HiddenField();
			copiesIncludedNumberLight
				.setFieldName('copies_included_number_light')
				.setValue(0);
			
			const copiesIncludedPriceLight = new HiddenField();
			copiesIncludedPriceLight
				.setFieldName('copies_included_price_light')
				.setValue(product.cc_light_transfer_price)
				.setReadonly();
				
			copiesIncludedFieldsetFields.push(copiesIncludedNumberLight);
			copiesIncludedFieldsetFields.push(copiesIncludedPriceLight);
		}
		
		const copiesIncludedFieldset = new Form();
		copiesIncludedFieldset
			.setTitle('Copie incluse')
			.addClasses('fieldset-inline')
			.addFields(copiesIncludedFieldsetFields);
		
		// copies extra
		const copiesExtraFieldsetFields = [];
		if(product.cc_bn_transfer_extra_price > 0) // bn
		{
			const copiesExtraPriceBw = new HiddenField();
			copiesExtraPriceBw
				.setFieldName('copies_extra_price_bw')
				.addClasses('copies-price-single')
				.setValue(product.cc_bn_transfer_extra_price);
				
			copiesExtraFieldsetFields.push(copiesExtraPriceBw);
		}
		
		if(product.cc_color_transfer_extra_price > 0) // color
		{				
			const copiesExtraPriceColors = new HiddenField();
			copiesExtraPriceColors
				.setFieldName('copies_extra_price_colors')
				.addClasses('copies-price-single')
				.setValue(product.cc_color_transfer_extra_price);
				
			copiesExtraFieldsetFields.push(copiesExtraPriceColors);
		}
		
		if(product.cc_light_transfer_extra_price  > 0) // light
		{
			const copiesExtraPriceLight = new PriceField();
			copiesExtraPriceLight
				.setFieldName('copies_extra_price_light')
				.addClasses('copies-price-single')
				.setValue(product.cc_light_transfer_extra_price);
				
			copiesExtraFieldsetFields.push(copiesExtraPriceLight);
		}
		
		const copiesExtraFieldset = new Form();
		copiesExtraFieldset
			.addClasses('hidden')
			.addFields(copiesExtraFieldsetFields);
		
		// delivery
		const delivery_times = new TextField();
		delivery_times
			.setFieldName('delivery_times')
			.setLabel('Orari per la consegna');
			
		const floor_delivery = new SelectBooleanField();
		floor_delivery
			.setFieldName('floor_delivery')
			.setLabel('Consegna al piano');
			
		const floor_num = new TextField();
		floor_num
			.setFieldName('floor_num')
			.setLabel('Piano di consegna');
		
		const fe_present = new SelectBooleanField();
		fe_present
			.setFieldName('fe_present')
			.setLabel('Disponibilità ascensore')
			.setValue(0);
			
		const fe_width = new TextField();
		fe_width
			.setFieldName('fe_width')
			.setLabel('Larghezza utile porta');
			
		const fe_depth = new TextField();
		fe_depth
			.setFieldName('fe_depth')
			.setLabel('Prefondità interna');
			
		const deliveryElevatorFieldset = new Form();
		deliveryElevatorFieldset
			.addClasses('fieldset-delivery-elevator fieldset-inline')
			.addFields([
				fe_present,
				fe_width,
				fe_depth
			]);
		
		const st_type = new SelectField();
		st_type
			.setFieldName('st_type')
			.setLabel('Tipologia di scale');
		const stairsTypologyOpt1 = new OptionField();
		stairsTypologyOpt1
			.setValue('1')
			.setLabel('Tipo 1');
		const stairsTypologyOpt2 = new OptionField();
		stairsTypologyOpt2
			.setValue('2')
			.setLabel('Tipo 2');
		const stairsTypologyOpt3 = new OptionField();
		stairsTypologyOpt3
			.setValue('3')
			.setLabel('Tipo 3');
		const stairsTypologyOpt4 = new OptionField();
		stairsTypologyOpt4
			.setValue('4')
			.setLabel('Tipo 4');
		st_type.addOptions([
			stairsTypologyOpt1,
			stairsTypologyOpt2,
			stairsTypologyOpt3,
			stairsTypologyOpt4
		]);
		
		const st_num = new TextField();
		st_num
			.setFieldName('st_num')
			.setLabel('Numero di rampe');
			
		const st_width = new TextField();
		st_width
			.setFieldName('st_width')
			.setLabel('Larghezza delle scale');
			
		const st_depth = new TextField();
		st_depth
			.setFieldName('st_depth')
			.setLabel('Profondità delle scale');
			
		const deliveryStairsFieldset = new Form();
		deliveryStairsFieldset
			.addClasses('fieldset-delivery-stairs fieldset-inline')
			.addFields([
				st_type,
				st_num,
				st_width,
				st_depth
			]);
			
		const deliveryFieldset = new Form();
		deliveryFieldset
			.setTitle('Consegna')
			.addClasses('fieldset-delivery')
			.addFields([
				delivery_times,
				floor_delivery,
				floor_num,
				deliveryElevatorFieldset,
				deliveryStairsFieldset
			]);
			
		// network
		const internet = new SelectBooleanField();
		internet
			.setFieldName('internet')
			.setLabel('Presenza Internet');
		
		const net_type = new SelectField();
		net_type
			.setFieldName('net_type')
			.setLabel('Tipologia di postazioni');
		const networkTypeOptWindows = new OptionField();
		networkTypeOptWindows
			.setValue('0')
			.setLabel('Windows');
		const networkTypeOptLinux = new OptionField();
		networkTypeOptLinux
			.setValue('1')
			.setLabel('Linux');
		const networkTypeOptMac = new OptionField();
		networkTypeOptMac
			.setValue('2')
			.setLabel('Mac');
		net_type.addOptions([
			networkTypeOptWindows,
			networkTypeOptLinux,
			networkTypeOptMac
		]);
			
		const net_domain = new SelectBooleanField();
		net_domain
			.setFieldName('net_domain')
			.setLabel('Rete a dominio');
			
		const net_password = new TextField();
		net_password
			.setFieldName('net_password')
			.setLabel('Password di rete');
			
		const net_cable = new SelectField();
		net_cable
			.setFieldName('net_cable')
			.setLabel('Cavo di rete');
		const networkCableOptNo = new OptionField();
		networkCableOptNo
			.setValue('no')
			.setLabel('No');
		const networkCableOpt1m = new OptionField();
		networkCableOpt1m
			.setValue('1m')
			.setLabel('1m');
		const networkCableOpt2m = new OptionField();
		networkCableOpt2m
			.setValue('2m')
			.setLabel('2m');
		const networkCableOpt3m = new OptionField();
		networkCableOpt3m
			.setValue('3m')
			.setLabel('3m');
		const networkCableOpt5m = new OptionField();
		networkCableOpt5m
			.setValue('5m')
			.setLabel('5m');
		net_cable.addOptions([
			networkCableOptNo,
			networkCableOpt1m,
			networkCableOpt2m,
			networkCableOpt3m,
			networkCableOpt5m
		]);
		
		const rooms_ready = new SelectBooleanField();
		rooms_ready
			.setFieldName('rooms_ready')
			.setLabel('Locali pronti');
			
		const num_pc = new NumberField();
		num_pc
			.setFieldName('num_pc')
			.setLabel('Numero postazioni');
			
		const networkFieldset = new Form();
		networkFieldset
			.setTitle('Rete')
			.addClasses('fieldset-network')
			.addFields([
				internet,
				net_type,
				net_domain,
				net_password,
				net_cable,
				rooms_ready,
				num_pc
			]);
		
		// reference office
		const ref_office = new TextField();
		ref_office
			.setFieldName('ref_office')
			.setLabel('Ufficio di riferimento')
			.addWrapperClasses('formfield-ref_office');
		
		// notes
		const notes = new TextareaField();
		notes
			.setFieldName('notes')
			.setLabel('Note')
			.addWrapperClasses('formfield-notes');
			
		// set values
		if(this.data !== false){
			copiesIncludedNumberBw.setValue(this.data.copies_included_number_bw);
			copiesIncludedNumberColors.setValue(this.data.copies_included_number_colors);
			copiesExtraPriceBw.setValue(this.data.copies_extra_price_bw);
			copiesExtraPriceColors.setValue(this.data.copies_extra_price_colors);
			copiesExtraNumberBw.setValue(this.data.copies_extra_number_bw);
			copiesExtraNumberColors.setValue(this.data.copies_extra_number_colors);
			
			// TODO: retrieve probably from data[shipping]
			delivery_times.setValue(this.data.delivery_times);
			floor_delivery.setValue(this.data.floor_delivery);
			floor_num.setValue(this.data.floor_num);
			fe_present.setValue(this.data.fe_present);
			fe_width.setValue(this.data.fe_width);
			fe_depth.setValue(this.data.fe_depth);
			st_type.setValue(this.data.st_type);
			st_num.setValue(this.data.st_num);
			st_width.setValue(this.data.st_width);
			st_depth.setValue(this.data.st_depth);
			internet.setValue(this.data.internet);
			net_type.setValue(this.data.net_type);
			net_domain.setValue(this.data.net_domain);
			net_password.setValue(this.data.net_password);
			net_cable.setValue(this.data.net_cable);
			rooms_ready.setValue(this.data.rooms_ready);
			num_pc.setValue(this.data.num_pc);
			num_mac.setValue(this.data.num_mac);
			ref_office.setValue(this.data.ref_office);
			notes.setValue(this.data.notes);
		}
		
		// precompile shipping values
		const shippingTemplates = new SelectField();
		shippingTemplates
			.setFieldName('id_shipping')
			.setRequired(true)
			.setLabel('Precompila dati spedizione');
		const shippingTemplatesOpts = [];
		const emptyShippingTemplateOpt = new OptionField();
		emptyShippingTemplateOpt
			.setValue('')
			.setLabel('Se vuoi, seleziona un valore precedente per i seguenti campi');
		shippingTemplatesOpts.push(emptyShippingTemplateOpt);    
		shippingTemplates.addOptions(shippingTemplatesOpts);
		
		const shippingFieldset = new Form();
		shippingFieldset
			.setTitle('Spedizione')
			.addClasses('fieldset-shipping')
			.addFields([
				shippingTemplates,
				ref_office,
				notes,
				deliveryFieldset,
				networkFieldset
			]);
			
		const rowForm = new Form();
		rowForm
			.setAction('/')
			.addClasses('product-shipping fullwidth label-left');
		rowForm.addFields([
			idProduct,
			copiesIncludedFieldset,
			copiesExtraFieldset,
			locationFieldset,
			shippingFieldset
		]);
		
		return rowForm;
		
	}

	//
	// GETTERS
	//
	
	// retrieve customer locations
	async retrieveCustomerLocations(id_customer){
		if(id_customer){
			const customerLocationApi = new CustomerLocationApi();
			let customerLocations = await customerLocationApi.setCustomerId(parseInt(id_customer)).collection().catch(function(e){
				const errmsg = 'Non è stato possibile recuperare le sedi';
				$(document).trigger('message', [ 'error', errmsg ]);
				throw errmsg;
			});
			this.customerLocations = customerLocations.data;
			this.refreshCustomerLocations();
		}
	}
	
	// refresh customer locations
	refreshCustomerLocations(){
		if(this.customerLocations){
			
			// prepare new options
			let locationOpts = '';
			let emptyOpt = new OptionField();
			emptyOpt
				.setValue('')
				.setLabel('Seleziona');
			locationOpts += emptyOpt.render();
			this.customerLocations.map(function(location){
				const option = new OptionField();
				option
					.setValue(location.id)
					.setLabel(location.name);
				locationOpts += option.render();
			});
			
			// refresh in every OfferRow the location <select>
			$('[name="id_location"]').each(function(){
				$(this).html(locationOpts).trigger('change');
			});
			
		}
	}
	
	// retrieve customer location pre-compiled shipping templates
	async retrieveCustomerLocationShippings(id_customer, id_location){
		if(id_customer && id_location){
			const customerLocationShippingApi = new CustomerLocationShippingApi();
			let customerLocationShippings = await customerLocationShippingApi.setCustomerId(parseInt(id_customer)).setLocationId(parseInt(id_location)).collection().catch(function(e){
				const errmsg = 'Non è stato possibile recuperare i template di spedizione';
				$(document).trigger('message', [ 'error', errmsg ]);
				throw errmsg;
			});
			this.customerLocationShippings = customerLocationShippings.data;
			this.refreshCustomerLocationShippings();
		}
	}
	
	// refresh customer location shippings
	refreshCustomerLocationShippings(){
		if(this.customerLocationShippings){
			
			// prepare new options
			let shippingOpts = '';
			let emptyOpt = new OptionField();
			emptyOpt
				.setValue('')
				.setLabel('Se vuoi, seleziona un valore precedente per i seguenti campi');
			shippingOpts += emptyOpt.render();
			this.customerLocationShippings.map(function(location){
				const option = new OptionField();
				option
					.setValue(location.id)
					.setLabel('Opzione ' + location.id);
				shippingOpts += option.render();
			});
			
			// refresh in every OfferRow location shippings <select>
			$('[name="id_shipping"]').each(function(){
				$(this).html(shippingOpts).trigger('change');
			});
			
		}
	}
	
	//
	// FILLERS
	//
	
	// fill customer fieldset
	fillCustomerFieldset(id_customer){
		const selectedCustomer = this.customers.find(x => x.id === parseInt(id_customer));
		if(selectedCustomer){
			$('.fieldset-anagrafica input').each(function(){
				$(this).val(selectedCustomer[$(this).attr('name')]);
			});
		} else {
			$('.fieldset-anagrafica input').each(function(){
				$(this).val('');
			});
		}
	}
	
	// fill customer location fieldset
	fillCustomerLocationFieldset($container, id_location){
		const selectedCustomerLocation = this.customerLocations.find(x => x.id === parseInt(id_location));
		if(selectedCustomerLocation){
			$('input, textarea, select:not([name="id_location"])', $container).each(function(){
				$(this).val(selectedCustomerLocation[$(this).attr('name')]);
			});
		} else {
			$('input, textarea, select:not([name="id_location"])', $container).each(function(){
				$(this).val('');
			});
		}
	}
	
	// fill customer location shipping fieldset
	fillCustomerLocationShippingFieldset($containers, id_shipping){
		const selectedCustomerLocationShipping = this.customerLocationShippings.find(x => x.id === parseInt(id_shipping));
		if(selectedCustomerLocationShipping){
			$containers.forEach(($container) => {
				$('input, textarea, select:not([name="id_shipping"])', $container).each(function(){
					$(this).val(selectedCustomerLocationShipping[$(this).attr('name')]).trigger('change');
				});
			});
		} else {
			$containers.forEach(($container) => {
				$('input, textarea, select:not([name="id_shipping"])', $container).each(function(){
					$(this).val('').trigger('change');
				});
			});
		}
	}
	
	// filter available copies totals (by type of products)
	filterTotalCopiesFields(){
		$('.copies-total').each(function(){
			const copytype = $(this).attr('data-copytype');
			if($(`.copies-single[data-copytype="${copytype}"]`).length){
				$(this).closest('fieldset').removeClass('hidden');
			} else {
				$(this).closest('fieldset').addClass('hidden').val(0);
			}
		});
	}
	
	// set total prices
	setTotalPricesFields(){
		$('.copies-price-total').each(function(){
			const copytype = $(this).attr('data-copytype');
			const copysale = $(this).attr('data-copysale');
			let price = 0;
			$(`.copies-price-single[data-copytype="${copytype}"][data-copysale="${copysale}"]`).each(function(){
				price = Math.max(price, parseFloat($(this).val()));
			});
			$(this).val(price);
		});
	}
	
	// calculate final prices
	calculateFinalPrices($trigger = false){
		if($trigger === false){
			
		} else if($trigger.hasClass('copies-single') || $trigger.hasClass('copies-margin-total')){
			const copytype = $trigger.attr('data-copytype');
			const copysale = $trigger.attr('data-copysale');
			const num = parseInt($(`.copies-total[data-copytype="${copytype}"][data-copysale="${copysale}"]`).val());
			const price = parseFloat($(`.copies-price-total[data-copytype="${copytype}"][data-copysale="${copysale}"]`).val()).toPrecision(5);
			const margin = parseFloat($(`.copies-margin-total[data-copytype="${copytype}"][data-copysale="${copysale}"]`).val()).toPrecision(5);
			
			$(`.copies-price-final[data-copytype="${copytype}"][data-copysale="${copysale}"]`).val(parseFloat(num * price + (num * price * margin / 100)).toPrecision(5));
			
		} else if($trigger.hasClass('copies-price-final')){
			const copytype = $trigger.attr('data-copytype');
			const copysale = $trigger.attr('data-copysale');
			const startingPrice = (parseInt($(`.copies-total[data-copytype="${copytype}"][data-copysale="${copysale}"]`).val()) * parseFloat($(`.copies-price-total[data-copytype="${copytype}"][data-copysale="${copysale}"]`).val())).toPrecision(5);
			const finalPrice = parseFloat($trigger.val()).toPrecision(5);
			
			$(`.copies-margin-total[data-copytype="${copytype}"][data-copysale="${copysale}"]`).val(parseFloat((finalPrice - startingPrice) / startingPrice * 100).toPrecision(5));
			
		}
	}
	
	//
	// EVENTS
	//

	// custom interactions on fields
	attachInteractions(trigger = false){
		
		const _this = this;
		
		// add data-* (TODO: waiting for implementation in Component)
		$('[name="copies_included_number_bw"],[name="copies_included_total_number_bw"]').attr('data-copytype', 'bn').attr('data-copysale', 'included');
		$('[name="copies_included_number_colors"],[name="copies_included_total_number_colors"]').attr('data-copytype', 'color').attr('data-copysale', 'included');
		$('[name="copies_included_number_light"],[name="copies_included_total_number_light"]').attr('data-copytype', 'light').attr('data-copysale', 'included');
		$('[name="copies_extra_number_bw"],[name="copies_extra_total_number_bw"]').attr('data-copytype', 'bn').attr('data-copysale', 'extra');
		$('[name="copies_extra_number_colors"],[name="copies_extra_total_number_colors"]').attr('data-copytype', 'color').attr('data-copysale', 'extra');
		$('[name="copies_extra_number_light"],[name="copies_extra_total_number_light"]').attr('data-copytype', 'light').attr('data-copysale', 'extra');
		$('[name="copies_included_price_bw"],[name="copies_included_total_price_bw"]').attr('data-copytype', 'bn').attr('data-copysale', 'included');
		$('[name="copies_included_price_colors"],[name="copies_included_total_price_colors"]').attr('data-copytype', 'color').attr('data-copysale', 'included');
		$('[name="copies_included_price_light"],[name="copies_included_total_price_light"]').attr('data-copytype', 'light').attr('data-copysale', 'included');
		$('[name="copies_extra_price_bw"],[name="copies_extra_total_margin_bw"],[name="copies_extra_total_start_price_bw"],[name="copies_extra_total_price_bw"]').attr('data-copytype', 'bn').attr('data-copysale', 'extra');
		$('[name="copies_extra_price_colors"],[name="copies_extra_total_margin_colors"],[name="copies_extra_total_start_price_colors"],[name="copies_extra_total_price_colors"]').attr('data-copytype', 'color').attr('data-copysale', 'extra');
		$('[name="copies_extra_price_light"],[name="copies_extra_total_margin_light"],[name="copies_extra_total_start_price_light"],[name="copies_extra_total_price_light"]').attr('data-copytype', 'light').attr('data-copysale', 'extra');
		
		// customer
		$('[name="id_customer"]').on('change', function(){
			_this.fillCustomerFieldset($(this).val());
			_this.retrieveCustomerLocations($(this).val());
			$('.fieldset-anagrafica').toggleClass('hidden', $(this).val() === '');
		});
		
		// location
		$('[name="id_location"]').on('change', function(){
			$('[name="opening_time"], [name="ztl"], [name="notes"]', $(this).closest('fieldset')).closest('.formfield').toggleClass('hidden', $(this).val() === '');
			$('.fieldset-location-address, .fieldset-location-ref', $(this).closest('fieldset')).toggleClass('hidden', $(this).val() === '');
			$('.fieldset-shipping', $(this).closest('form')).toggleClass('hidden', $(this).val() === '');
			_this.fillCustomerLocationFieldset($(this).closest('fieldset'), $(this).val());
			_this.retrieveCustomerLocationShippings($('[name="id_customer"]').val(), $(this).val());
		});
		
		// location shipping
		$('[name="id_shipping"]').on('change', function(){
			const $form = $(this).closest('form');
			_this.fillCustomerLocationShippingFieldset([$('.fieldset-shipping', $form)], $(this).val());
		});
		
		// floor delivery
		$('[name="floor_delivery"]').on('change', function(){
			let $fieldset = $(this).closest('fieldset');
			$('[name="floor_num"]', $fieldset).closest('.formfield').toggleClass('hidden', $(this).val() === '0');
			$('.fieldset-delivery-elevator, .fieldset-delivery-stairs', $fieldset).toggleClass('hidden', $(this).val() === '0');
			if($(this).val() === '0'){
				$('[name="fe_present"]', $fieldset).val('0').trigger('change');
			}
		});
		
		// elevator
		$('[name="fe_present"]').on('change', function(){
			$('[name="fe_width"], [name="fe_depth"]', $(this).closest('fieldset')).closest('.formfield').toggleClass('hidden', $(this).val() === '0');
		});
		
		// takeover
		$('[name="takeover"]').on('change', function(){
			$('[name="takeover_notes"], [name="takeover_topup"]', $(this).closest('form')).closest('.formfield').toggleClass('hidden', $(this).val() === '0');
		});
		
		// pickup
		$('[name="pickup"]').on('change', function(){
			$('[name="pickup_description"], [name="pickup_value"]', $(this).closest('form')).closest('.formfield').toggleClass('hidden', $(this).val() === '0');
		});
		
		// total number of copies
		$(document).on('change', '.copies-single', function(){
			const copytype = $(this).attr('data-copytype');
			const copysale = $(this).attr('data-copysale');
			let totalCopies = 0;
			$(`.copies-single[data-copytype="${copytype}"][data-copysale="${copysale}"]`).each(function(){
				totalCopies += parseInt($(this).val());
			});
			$(`.copies-total[data-copytype="${copytype}"][data-copysale="${copysale}"]`).val(totalCopies);
			_this.calculateFinalPrices($(this));
		});
		
		// margin on total number of copies
		$(document).on('change', '.copies-margin-total', function(){
			_this.calculateFinalPrices($(this));
		});
		
		// total price of copies
		$(document).on('change', '.copies-price-final', function(){
			_this.calculateFinalPrices($(this));
		});
		
		// collapsible fieldsets
		$(document).on('click', 'fieldset.collapsible > .fieldsettitle', function(){
			$(this).closest('.collapsible').toggleClass('collapsed');
		});
		
		if(trigger){
			$('[name="id_customer"], [name="id_location"], [name="floor_delivery"], [name="fe_present"], [name="takeover"], [name="pickup"]').trigger('change');
			this.filterTotalCopiesFields();
			this.setTotalPricesFields();
			$('[name="copies_extra_total_margin_bw"], [name="copies_extra_total_margin_colors"], [name="copies_extra_total_margin_light"]').trigger('change');
		}
		
		// goto previous step
		$('.prev').on('click', function(e){
			e.preventDefault();
			_this.wizard.prevScreen();
		});
		
		// form submission
		$('form').on('submit', function(e){
			e.preventDefault();
			if($(this).hasClass('wizard-last')){
				_this.submitOffer();
			} else {
				_this.wizard.nextScreen();
			}
		});
		
	}
	
	// TODO
	submitOffer(){
		console.log('submit offer');
		/*let values = offerForm.getValues();
		const offerApi = new OfferApi();
		offerApi.setForm(offerForm);
		let msg = '';
		if (in_edit) {
			const res = await offerApi.put(offer_id, values);
			msg = 'Offerta modificata con successo';
		} else {
			const res = await offerApi.post(values);
			offer_id = res.data.id;
			msg = res.data.message;
		}
		if (!offerForm.hasErrors()) {
			$(document).trigger('message', [ 'success', msg ]);
			$.spa.navigate('/offer/manage/' + offer_id, true);
		}*/
	}
	
}
