const $ = window.jQuery;

import _ from "lodash";
import Component from "../Component";

export default class GridList extends Component {

  constructor() {
    super();
    this.name = 'gridlist';

    this.records = [];
    this.total = 0;
    this.cell_cb = undefined;
    this.api = undefined;
    this.dynamic_param = undefined;
    this.filters = {};
    this.empty_html = 'No item';
    this.default_pages = [
      25,
      50,
      75,
      100
    ];
    this.default_page = 25;
    this.order_fields = [];
    this.default_order = undefined;
    this.default_order_dir = undefined;
    this.paginator_width = 5;

    this.filters = {
      limit: this.default_page,
      page: 0
    };
    this._prerendered = false;
  }

  setRecords(records) {
    this.records = records;

    return this;
  }

  setTotal(total) {
    this.total = total;

    return this;
  }

  setCellCallback(cb) {
    this.cell_cb = cb;

    return this;
  }

  setApi(api, dynamic_param) {
    this.api = api;
    this.dynamic_param = dynamic_param;

    return this;
  }

  setDynamicParam(dynamic_param) {
    this.dynamic_param = dynamic_param;

    return this;
  }

  setOrdering(fields, default_field, default_direction) {
    if (fields.indexOf(default_field) === -1) {
      throw 'The defult order field is not defined in available fields';
    }
    this.order_fields = fields;
    this.filters.order_field = this.default_order = default_field;
    if (typeof default_direction === 'undefined' || [ 'asc', 'desc' ].indexOf(default_direction) === -1) {
      throw 'You have to define the default order direction: asc / desc';
    }
    this.filters.order_dir = this.default_order_dir = default_direction;

    return this;
  }

  setDefaultPage(default_page) {
    this.filters.limit.default_page = this.default_page = default_page;

    return this;
  }

  setDefaultPages(default_pages) {
    this.default_pages = default_pages;

    return this;
  }

  setEmptyHTML(empty_html) {
    this.empty_html = empty_html;

    return this;
  }

  setPaginatorWidth(width) {
    this.paginator_width = width;

    return this;
  }

  getTotalPages() {
    return Math.ceil(this.total / this.filters.limit);
  }

  getPaginator(total) {
    let code = '<ul>';
    const current_page = parseInt(this.filters.page);
    const pages_spread = Math.floor(this.paginator_width / 2);

    const iterators = {
      first: 0,
      previous: current_page - 1,
      next: current_page + 1,
      last: total - 1
    };

    Object.keys(iterators).forEach(function (iterator) {
      let page = iterators[iterator];
      let disabled = '';
      let href = '';
      if (
        (page < 0 || page >= total) ||
        (total < 2) ||
        ((iterator === 'first' || iterator === 'previous') && current_page === 0) ||
        ((iterator === 'next' || iterator === 'last') && current_page === (total - 1))
      ) {
        page = '';
        disabled = ' scary-muted';
      } else {
        href = ' href="#"';
      }
      code += `<li class="pag-${iterator}${disabled}"><a${href} data-page="${page}">${iterator}</a></li>`;
      if (iterator === 'previous') {
        const start = Math.max(0, current_page - pages_spread);
        const end = Math.min(total - 1, current_page + pages_spread);
        let counter = 1;
        for (let p = start; p <= end; p++) {
          const pstate = p === current_page ? ' active' : '';
          const phref = p === current_page ? '' : ' href="#"';
          const pnum = p === current_page ? '' : p;
          code += `<li class="pag-${counter}${pstate}"><a${phref} data-page="${pnum}">${p + 1}</a></li>`;
          counter++;
        }
      }
    });

    code += '</ul>';

    return code;
  }

  process(ht_id) {
    return this.run().then(function (gl) {
      $('#' + ht_id + ' .gridlist-wrapper').html(gl.renderHtml());
    });
  }

  async run() {
    this.api.setDynamicParam(this.dynamic_param);
    this.api.setTargetElement(this._prerendered ? '#' + this._prerendered : 'body');

    const api_call = await this.api.collection(this.filters);
    this.setRecords(api_call.data);
    this.setTotal(api_call.total);

    return this;
  }

  renderHtml() {
    const _this = this;
    const cells = [];
    if (this.records.length > 0) {
      this.records.forEach(function (record) {
        cells.push('<li>' + _this.cell_cb.bind(_this)(record) + '</li>');
      });
      const total = this.getTotalPages();
      const paginator_items = this.getPaginator(total);

      return `
        <ul>
          ${cells.join(' ')}
        </ul>
        <div class="paginator">
          <div class="description">Page ${parseInt(this.filters.page) + 1} of ${total} (${this.total} items)</div>
          <div class="paginator-items">${paginator_items}</div>
        </div>
      `;
    } else {
      return this.empty_html;
    }
  }

  render() {
    const _this = this;
    let ht_id;
    if (!this._prerendered) {
      ht_id = this._prerendered = this.getUniqId();
      $(document).on('keyup blur', '#search-' + ht_id, function (e) {
        e.preventDefault();
        const value = $(this).val();
        $('#' + ht_id + ' .clear-search').toggleClass('scary-muted', value.length === 0);
        _.debounce(function () {
          _this.filters.search = value;
          _this.process(ht_id);
        }, 200)();
      });
      $(document).on('click', '#' + ht_id + ' .clear-search', function (e) {
        e.preventDefault();
        if (_this.filters.search) {
          _this.filters.search = undefined;
          $('input#search-' + ht_id).val('').trigger('keyup');
        }
      });
      $(document).on('change', '#' + ht_id + ' .scary-orderfield', function () {
        _this.filters.order_field = $(this).val();
        _this.process(ht_id);
      });
      $(document).on('change', '#' + ht_id + ' .scary-orderdir', function () {
        _this.filters.order_dir = $(this).val();
        _this.process(ht_id);
      });
      $(document).on('change', '#' + ht_id + ' .scary-pagination', function () {
        _this.filters.limit = $(this).val();
        _this.process(ht_id);
      });
      $(document).on('click', '#' + ht_id + ' .paginator .paginator-items ul li', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        const pagenum = $(this).find('a').first().attr('data-page');
        if (pagenum != '') {
          _this.filters.page = pagenum;
          _this
            .process(ht_id)
          /*  .then(function (gl) { // uncomment this to force scroll top after page change
              $(gl).parents('*').forEach(function (parent) {
                $(parent).scrollTop(0);
              });
            })*/;
        }
      });
      $(document).on('click', '#' + ht_id + ' .switch-view a', function (e) {
        e.preventDefault();
        const swclass = $(this).hasClass('switch-grid') ? 'grid' : 'list';
        const exclass = $(this).hasClass('switch-list') ? 'grid' : 'list';

        $('#' + ht_id).removeClass(exclass).addClass(swclass);
      });
    } else {
      ht_id = this._prerendered;
    }
    const pagination_opts = this.default_pages.map(function (default_page) {
      const selected = _this.default_page === default_page ? ' selected' : '';
      return `<option value="${default_page}"${selected}>${default_page}</option>`;
    });
    const order_field_opts = this.order_fields.map(function (order_field) {
      const selected = _this.default_order === order_field ? ' selected' : '';
      return `<option value="${order_field}"${selected}>${order_field}</option>`;
    });
    const order_dir_opts = [
      '<option value="asc" selected>Low to High</option>',
      '<option value="desc">High to Low</option>'
    ];
    const html = this.renderHtml();

    return `
      <div id="${ht_id}" class="${this.classes.join(' ')}">
        <div class="gridlist-toolbar">
          <div class="switch-view">
            <a href="#" class="switch-grid">Grid</a>
            <a href="#" class="switch-list">List</a>
          </div>
          <div class="pagination">
            <select id="pagination-${ht_id}" class="scary-pagination">
              ${pagination_opts}
            </select>
          </div>
          <div class="ordering">
            <select id="orderfield-${ht_id}" class="scary-orderfield">
              ${order_field_opts}
            </select>
            <select id="orderdir-${ht_id}" class="scary-orderdir">
              ${order_dir_opts}
            </select>
          </div>
          <div class="search">
            <input type="text" id="search-${ht_id}" /><span class="clear-search scary-muted pointer">&times;</span>
          </div>
        </div>
        <div class="gridlist-wrapper">
          ${html}
        </div>        
      </div>
    `;
  }
}