const $ = window.jQuery;

import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import ProductApi from "../api/ProductApi";
import _ from "lodash";

export default class ProductsPage extends Page {

  constructor() {
    super();

    this.setTitle('Prodotti')
      .setMeta('description', 'Prodotti nel sistema')
      .setBreadcrumb('Prodotti');
  }

  content() {
	
	//
	// actions menu
	//
	
    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-products']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_add = new ActionsMenuItem();
	btn_add
		.addClasses([ 'element', 'only-icon', 'add' ])
		.setTitle('Aggiungi')
		.setOn('click', () => $.spa.navigate('/product/manage/create', true))
		.setRoles('RD_ADMIN_USERS');
		
	const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Modifica')
		.setRoles('RD_ADMIN_USERS');
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles('RD_ADMIN_USERS');
		
	actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);

	//
	// products table
	//
	
	// $.confirm delete
	const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare il prodotto?');

	// quick-open dt row on double-click
	$(document).on('dblclick', '.dt-products tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/product/manage/${targetId}`, true);
	});
		
	// handle dt_products select/deselect
	const dt_products_onSelectDeselect = function(e, dt, type, indexes){
		
		// check for selected rows
		let targetIds = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				targetIds.push(parseInt($(dt.row(idx).node()).find('td').first().text()));
			});
		}
		
		$(`#${btn_edit.name}, #${btn_delete.name}`)
			.off('click')
			.toggleClass('disabled', targetIds.length === 0);
		
		if(targetIds.length > 0){
			$(`#${btn_edit.name}`).on('click', () => $.spa.navigate(`/product/manage/${targetIds[0]}`, true));
			$(`#${btn_delete.name}`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const productApi = new ProductApi();
						await productApi.delete(targetIds[0])
							.then(() => {
								$.spa.navigate('/products', true);
							})
							.catch((e) => {
								$(document).trigger('message', [ 'error', e.responseJSON.error ]);
							});
					})
					.render();
			});
		}
		
	};
	const debounced_dt_products_onSelectDeselect = _.debounce(dt_products_onSelectDeselect, 100);
	
	const dt_products = new ScaryDataTable();
	dt_products
		.setUrl('/product')
		.setSelect('single')
		.setOnSelect(debounced_dt_products_onSelectDeselect)
		.setOnDeselect(debounced_dt_products_onSelectDeselect)
		.addField('id', 'ID')
		.addField('id_catalog', 'ID Catalog')
		.addField('id_brand', 'ID Brand')
		.addField('name', 'Nome')
		.addField('artcode_erp', 'Codice ERP')
		.addField('type', 'Tipologia')
		.addField('description', 'Descrizione')
		.addField('format', 'Formato')
		.addField('available', 'Disponibile')
		.addField('base_price', 'Prezzo Base')
		.addField('transfer_price', 'Prezzo Vendita')
		.addField('cc_bn_base_price', 'Cc BN Prezzo Base')
		.addField('cc_bn_transfer_price', 'Cc BN Prezzo Vendita')
		.addField('cc_color_base_price', 'Cc ColorePrezzo Base')
		.addField('cc_color_transfer_price', 'Cc Colore Prezzo Vendita')
		.addField('cc_light_base_price', 'Cc Prezzo Base Leggero')
		.addField('cc_light_transfer_price', 'Cc Prezzo Vendita Leggero')
		.addField('cc_max_bn', 'Max Copie BN')
		.addField('cc_max_color', 'Max Copie Colore')
		.addField('cc_suggested_bn', 'Copie BN Suggerite')
		.addField('cc_suggested_color', 'Copie Colore Suggerite')
		.addField('weight', 'Posizione in Categoria')
		//.setResponsivePriority('id', 0)
		.setResponsivePriority('name', 0)
		.setResponsivePriority('artcode_erp', 1)
		.addClasses('dt-products');
		//.setResponsiveDetails(false)
		

	// render
    $('main .main').html(actions_menu.render() + dt_products.render());
    dt_products.activate();
	
  }

}
