import Api from "./Api";

export default class RolesApi extends Api {
  constructor() {
    super(undefined, undefined, undefined);
  }


  async call(cb) {
    return await this.__call(cb);
  }

  async roles() {
    this.method = 'GET';
    this.url = '/roles';

    return await this.call((data) => {
      return data;
    });
  }
}