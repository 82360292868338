import $ from "jquery";
window.jQuery = $;
require("./events/core");

export let classes = {};
export let vars = {
  routerStarted: false
};

$(window).on('actual-url', function (e, url) {
  vars.url = url;
});

export default class App {
  async bootstrap() {
    // Init functions
    $(document).trigger('init');

    // Set title as appname
    $(document).prop('title', APPNAME);

    // Load theme wrapper
    await $('#scary').load('wrapper.html', {}, async function () {
      // Do bootstrap API calls - Router gets initialized during this calls
      let bootstrap_apis = [];
      Object.keys(BOOTSTRAP_APIS).forEach(function (name) {
        bootstrap_apis.push(BOOTSTRAP_APIS[name]);
      });

      bootstrap_apis.sort(function (a, b) {
        if (a.weight < b.weight) {
          return -1;
        }
        if (a.weight > b.weight) {
          return 1;
        }
        return 0;
      });

      for (let api of bootstrap_apis) {
        if (api.hasOwnProperty('class')) {
          const api_obj = new classes[api['class']]();

          const res = await api_obj[api['function']]();

          if (!res.statusCode || res.statusCode > 299) {
            return false;
          }
        }
      }
    });
  }
}