const $ = window.jQuery;

import Page from "./Page";
import ActionsMenu from "../components/ActionsMenu/ActionsMenu";
import ActionsMenuItem from "../components/ActionsMenu/ActionsMenuItem";
import ScaryDataTable from "../components/Plugins/ScaryDataTable";
import ScaryJqueryConfirm from "../../../core/plugins/jqueryconfirm/js/components/ScaryJqueryConfirm";
import UserApi from "../api/UserApi";
import _ from "lodash";

export default class UsersPage extends Page {

  constructor() {
    super();
  }

  async init() {
	this.setTitle('Utenti')
      .setMeta('description', 'Utenti nel sistema')
      .setBreadcrumb('Utenti');

	return super.init();
  }

  content() {
	
	//
	// actions menu
	//
	
    const actions_menu = new ActionsMenu();
	actions_menu.addClasses(['actions-menu-container', 'actions-menu-container-users']);
	actions_menu.addUlClasses('actions-menu');
	
	const btn_add = new ActionsMenuItem();
	btn_add
		.addClasses([ 'element', 'only-icon', 'add' ])
		.setTitle('Aggiungi')
		.setOn('click', () => $.spa.navigate('/user/manage/create', true))
		.setRoles('RD_ADMIN_USERS');
		
	const btn_edit = new ActionsMenuItem();
	btn_edit
		.addClasses([ 'element', 'only-icon', 'edit', 'disabled' ])
		.setTitle('Modifica')
		.setRoles('RD_ADMIN_USERS');
		
	const btn_delete = new ActionsMenuItem();
	btn_delete
		.addClasses([ 'element', 'only-icon', 'delete', 'disabled' ])
		.setTitle('Elimina')
		.setRoles('RD_ADMIN_USERS');
		
	actions_menu.addItems([
		btn_add,
		btn_edit,
		btn_delete
	]);

	//
	// users table
	//
	
	// $.confirm delete
	const confirmDelete = new ScaryJqueryConfirm();
	confirmDelete
		.setTitle('Attenzione')
		.setContent('Sei sicuro di voler cancellare l’utente?');
	
	// quick-open dt row on double-click
	$(document).on('dblclick', '.dt-users tbody tr', function(){
		let targetId = parseInt($(this).closest('tr').find('td').first().text());
		$.spa.navigate(`/user/manage/${targetId}`, true);
	});

	// handle dt_users select/deselect
	const dt_users_onSelectDeselect = function(e, dt, type, indexes){
		
		// check for selected rows
		let targetIds = [];
		if (type === 'row') {
			dt.rows({selected: true}).every(function(idx){
				targetIds.push(parseInt($(dt.row(idx).node()).find('td').first().text()));
			});
		}
		
		$(`#${btn_edit.name}, #${btn_delete.name}`)
			.off('click')
			.toggleClass('disabled', targetIds.length === 0);
		
		if(targetIds.length > 0){
			$(`#${btn_edit.name}`).on('click', () => $.spa.navigate(`/user/manage/${targetIds[0]}`, true));
			$(`#${btn_delete.name}`).on('click', () => {
				confirmDelete
					.modalConfirm(async (data) => {
						const userApi = new UserApi();
						await userApi.delete(targetIds[0])
							.then(() => {
								$.spa.navigate('/users', true);
							})
							.catch((e) => {
								$(document).trigger('message', [ 'error', e.responseJSON.error ]);
							});
					})
					.render();
			});
		}
		
	};
	const debounced_dt_users_onSelectDeselect = _.debounce(dt_users_onSelectDeselect, 200);
	
	const dt_users = new ScaryDataTable();
	dt_users
		.setUrl('/user')
		.setSelect('single')
		.setOnSelect(debounced_dt_users_onSelectDeselect)
		.setOnDeselect(debounced_dt_users_onSelectDeselect)
		.addField('id', 'ID')
		.addField('email', 'Email')
		.setResponsivePriority('email', 0)
		.addField('first_name', 'Nome')
		.setResponsivePriority('first_name', 2)
		.addField('last_name', 'Cognome')
		//.setResponsiveDetails(false)
		.setResponsivePriority('last_name', 1)
		.addField('dt_roles', 'Ruoli')
		.addClasses('dt-users');

	//
	// render
	//
	
    $('main .main').html(actions_menu.render() + dt_users.render());
    dt_users.activate();
	
  }

}
