const $ = window.jQuery;

import _ from "lodash";
import FormField from "../../../../core/js/components/Form/FormField";

export default class ButtonField extends FormField {

  constructor() {
    super();

    this.name = 'input';
    this.setType('button');
    this.href = "#";
    this.target = "top";
    this.hrefClass = "hrefButton";
  }

  onClick(url){
    this.href = url;
	
	return this;
  }

  addClassHref(hrefClass){
    this.hrefClass = hrefClass;
	
	return this;
  }

  render() {
    const disabled = this.disabled ? ' disabled' : '';
    const readonly = this.readonly ? ' readonly' : '';
    return this.wrap('<a href="' + this.href + '" target="'+this.target+'" class="'+this.hrefClass+'"><input type="' + this.type + '" id="' + this.name + '" class="' +
      this.classes.join(' ') + '" title="' + this.value + '" value="' + this.value + '" ' + disabled + readonly + '/></a>');
  }
}