const $ = window.jQuery;

import Component from "../../../../core/js/components/Component";

export default class Wizard extends Component {
	
	constructor(){
		super();
		this.name = this.getUniqId();
	
		this.title = undefined;
		this.screens = [];
		this.currentScreen = 0;
	}
	
	addTitle(title){
		this.title = title;
		
		return this;
	}
	
	addScreen(screen){
		this.screens.push(screen);
		
		return this;
	}
	
	gotoScreen(id){
		this.currentScreen = id;
		this.refreshScreen();
	}
	
	prevScreen(){
		if(this.currentScreen > 0){
			this.gotoScreen(this.currentScreen - 1);
		}
	}
	
	nextScreen(){
		if(this.currentScreen < this.screens.length){
			this.gotoScreen(this.currentScreen + 1);
		}
	}
	
	refreshScreen(){
		$(`.wizard-menu li[data-id="${this.currentScreen}"], .screen[data-id="${this.currentScreen}"]`, `#${this.name}`).addClass('current').siblings().removeClass('current');
	}

	render(){
		let htmlMenu = '';
		let htmlScreens = '';
		this.screens.forEach(function(item, idx){
			
			htmlMenu += `<li class="${idx === 0 ? 'current' : ''}" data-id="${idx}">
				<label>${item.label}</label>
			</li>`;
			
			let htmlContent = '';
			if(item.content){
				if(!Array.isArray(item.content)) item.content = [item.content];
				item.content.forEach((content) => {
					htmlContent += (typeof content === 'object' && typeof content.render === 'function') ? content.render() : content;
				});
			}
			htmlScreens += `<div class="screen${idx === 0 ? ' current' : ''}" data-id="${idx}">
				${htmlContent}
			</div>`;
		});
		
		let _this = this;
		$(document).on('click', `#${this.name} .wizard-menu > li`, function(){
			_this.gotoScreen($(this).index());
		});
		
		return `<div id="${this.name}" class="wizard-container ${this.classes.join(' ')}">
			<div class="wizard">
				${typeof this.title === 'undefined' ? '' : '<header>' + this.title + '</header>'}
				<ul class="wizard-menu">
					${htmlMenu}
				</ul>
				<div class="wizard-screens">
					${htmlScreens}
				</div>
			</div>
		</div>`;
	}
  
}
